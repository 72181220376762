import Badge from 'components/Elements/Badge';
import React from 'react'

function HeadingBadge({
    badge,
    title,
    description,
    color,
    alignment,
    whiteContent,
}) {

    const alignmentsRow = {
        left: "justify-start",
        center: "justify-center",
        right: "justify-end",
    };
    const alignmentsText = {
        left: "text-left",
        center: "text-center",
        right: "text-right",
    };

    return (
        <>
            <div className={"flex flex-wrap -mx-4 " + alignmentsRow[alignment]}>
                <div className={"px-4 relative w-full " + alignmentsText[alignment]}>
                    {badge && <Badge {...badge} />}
                    {title && (
                        <h2
                            className={
                                "text-4xl font-bold mt-3 mb-1 " +
                                (whiteContent ? "text-white" : "text-blueGray-700")
                            }
                        >
                            {title}
                        </h2>
                    )}
                    {description && (
                        <p
                            className={
                                "mt-2 mb-4 text-xl leading-relaxed " +
                                (whiteContent ? "text-white" : "text-blueGray-400")
                            }
                        >
                            {description}
                        </p>
                    )}
                </div>
            </div>
        </>
    )
}

export default HeadingBadge