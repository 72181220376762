const { useEffect, useState } = require("react");

const BubblesSelect = ({
    categories,
    onSelectCategory,
    onSelectSubcategory,
    selectedCategory,
    selectedSubcategory,
    enableOther,
    onOtherSelected,
    formField, // Add this prop
    form,
    setForm,
    isCustomSelected
}) => {

    // Local state for managing input value
    const [localInputValue, setLocalInputValue] = useState('');

    useEffect(() => {
        // Update local input value when selectedCategory changes
        setLocalInputValue(form[formField][selectedCategory] || '');
    }, [selectedCategory, form, formField]);

    const handleLocalInputChange = (e) => {
        setLocalInputValue(e.target.value);
    };

    // Update global form state when input loses focus or another similar event
    const handleInputBlur = () => {
        setForm((prevForm) => ({
            ...prevForm,
            [formField]: {
                ...prevForm[formField],
                [selectedCategory]: localInputValue,
            },
        }));
    };

    return (
        <div>
            <div className="main-categories">
                {Object.keys(categories).map((category) => (
                    <div className="category-and-badge" key={category}>
                        <div className={`${selectedCategory === category ? 'sub-categories' : ''
                            }`}>
                            <button onClick={() => onSelectCategory(category)}
                                className={`px-4 py-2 border text-sm font-medium main-category ${selectedCategory === category ? 'bg-teal-500 text-white' : 'bg-white text-gray-800'
                                    } hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500`}
                            >
                                {category ? category.replace("_", " ") : "Placeholder"}

                                {form[formField][category] && (
                                    <span
                                        className={`badge-for-category ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800`}>
                                        {form[formField][category]}
                                    </span>
                                )}

                            </button>
                            {selectedCategory === category && (
                                <div style={{ "marginTop": "15px" }}>
                                    {categories[category].map((subcategory) => (
                                        <button key={subcategory} onClick={() => onSelectSubcategory(formField, category, subcategory)}
                                            className={`px-4 py-2 rounded-full border text-sm font-medium ${selectedSubcategory === subcategory ? 'bg-teal-500 text-white' : 'bg-white text-gray-800'
                                                } hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500`}
                                        >
                                            {subcategory}
                                        </button>
                                    ))}
                                    <input
                                        type="text"
                                        value={localInputValue}
                                        onChange={handleLocalInputChange}
                                        onBlur={handleInputBlur} // Consider updating on blur to reduce re-renders
                                        className="px-4 py-2 ml-2 rounded-full border text-sm font-medium bg-light-teal"
                                        placeholder="Or add custom..."
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                ))}
                {enableOther && (
                    <button onClick={onOtherSelected}
                        className={`px-4 py-2 rounded-full  border text-sm font-medium  ${isCustomSelected ? 'bg-teal-500 text-white' : 'bg-white text-gray-800'
                            } hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500`}
                    >
                        Add MORE Custom Details 💫
                    </button>
                )}
            </div>

        </div>
    );
};

export default BubblesSelect