import React from 'react'

function CardComponent({ children, className }) {
    return (
        <div className={`rounded-lg flex bg-white w-full mb-6 shadow-xl ${className}`}>
            {children}
        </div>
    )
}

export default CardComponent