import Button from "components/Elements/Button";
import Input from "components/Elements/Input";
import React from "react";

function RegisterComponent({
  title,
  subtitle,
  inputs,
  socials,
  buttons = [],
  checkbox,
  forgotPassword,
  createAccount,
  errorMessage,
}) {
  return (
    <>
      <div className="relative flex flex-col w-full mb-6 shadow-lg rounded-lg bg-white">
        <div className="mb-0 px-6 py-6">
          <div className="text-center mb-3">
            <h6 className="text-blueGray-500 text-sm font-bold">{title}</h6>
          </div>
          <div className="text-center">
            {socials.map((prop, key) => (
              <Button
                size="sm"
                {...prop.button}
                key={key}
                color={prop.icon}
                fullWidth={false}
              >
                <i className={"mr-1 fab fa-" + prop.icon}></i> {prop.icon}
              </Button>
            ))}
          </div>
          <hr className="mt-6 border-b-1 border-blueGray-200" />
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <div className="text-blueGray-500 text-center mb-3 font-bold">
            <small>{subtitle}</small>
          </div>
          <form>
            {inputs.map((prop, key) => {
              return (
                <div key={key} className="relative w-full">
                  <label className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1">
                    {prop.label}
                  </label>
                  <Input {...prop.input} />
                </div>
              );
            })}

            {errorMessage != "" && (
              <p className="text-red-400 font-bold" style={{ color: "red" }}>
                {errorMessage}
              </p>
            )}

            {buttons.map((button) => (
              <div className="text-center mt-5">
                <Button {...button} />
              </div>
            ))}
          </form>
        </div>
      </div>
    </>
  );
}

export default RegisterComponent;
