import Button from "components/Elements/Button";
import { ProgressSpinner } from "primereact/progressspinner";
import React from "react";
import { useRef } from "react";
import HTMLFlipBook from "react-pageflip";
import { useSelector } from "react-redux";

function PreviewStory() {
  const  story  = useSelector((state) => state.story);
  const flipRef = useRef();

  // if (loadingStory) {
  //   return (
  //     <div className="w-full h-full flex items-center justify-center">
  //       <ProgressSpinner />
  //     </div>
  //   );
  // }

  return (
    <div className="w-full h-full bg-black p-2">
      <HTMLFlipBook
        width={1080}
        height={640}
        size="stretch"
        usePortrait={false}
        drawShadow={false}
        ref={(el) => {
          flipRef.current = el;
        }}
        // showCover={true}
        showPageCorners={true}
        autoSize={true}
        className="w-full h-full"
      >
        <div className="w-full h-full bg-black" />
        {/* <div className="w-full h-full bg-white">
          <h2 className="text-teal absolute bottom-1/2 left-1/2 margin-auto text-2xl font-bold">{story.title.replace("Title: ", "")}</h2>
        </div> */}
        <div
          className={`w-full h-full flex bg-white border-t-2 border-b-2 border-black`}
        >
          <img
            src={story.cover_url}
            className="relative top-0 w-full h-full"
            style={{ zIndex: "-1" }}
          />

          <div className="gradient-overlay"></div>
          <div className="absolute bottom-0 p-2 w-full">
            <p className=" text-white font-semibold font-serif text-center">
              {story.title.replace("Title: ", "")}
            </p>
          </div>
        </div>
        {/* <div className="w-full flex flex-row h-full page-2"></div> */}
        {/* <div className="w-full flex flex-row h-full page-2"></div> */}

        {story.content.map((item, index) => {
          console.log(item);
          return (
            <div
              className={`w-full h-full flex bg-white border-t-2 border-b-2 border-black  ${
                index % 2 == 0 ? "border-l-2" : "border-r-2"
              }`}
            >
              {true && (
                <>
                  {item.images.map((image_item) => {
                    if (image_item["selected"]) {
                      return (
                        <img
                          src={image_item.url}
                          className="relative top-0 w-full h-full"
                          style={{ zIndex: "-1" }}
                        />
                      );
                    }
                  })}
                  <div className="gradient-overlay"></div>
                </>
              )}
              <div className="absolute bottom-0 p-2 w-full">
                <p className=" text-white font-semibold font-serif text-center font-hannotate">
                  {item.text}
                </p>
              </div>
            </div>
          );
        })}

        <div className="w-full flex flex-row h-full page-2"></div>
      </HTMLFlipBook>
      {/* <div className="w-full flex flex-row items-center justify-center space-x-3 mt-3">
        <Button
          onClick={() => {
            flipRef.current.pageFlip().flipPrev();
          }}
        >
          Prev
        </Button>
        <Button
          color={"teal"}
          onClick={() => {
            flipRef.current.pageFlip().flipNext();
          }}
        >
          Next
        </Button>
      </div> */}
    </div>
  );
}

export default PreviewStory;
