export const categoryOptionsStoryline = {
  Genre: ["Action", "Comedy", "Fantasy", "Mystery", "Spy Fiction"],
  Setting: [
    "Outer Space",
    "School",
    "Prehistoric Times with Dinosaurs",
    "Underwater",
    "Wild West with Cowboys​",
  ],
};

export const categoryOptionsProtagonistCharacteristics = {
  Name: [],
  Age: ["3 to 4", "5 to 7", "8 to 10"],
  Gender: ["Male", "Female", "Other"],
  Hair_Color: ["Black", "Brown", "Blonde", "Red"],
  Eye_Color: ["Black", "Brown", "Blue", "Green"],
  Skin_Tone: ["White", "Brown", "Black"],
  Loves_To: ["Play with trucks", "Play with dolls", "Play with dinosours"],
};

export const categoryOptionsProtagonistCharacteristicsModeMit = {
  Name: ["John", "Lea", "Anna", "Kevin"],
  Gender: ["Male", "Female", "Other"],
  Favorite_Object: ["Laptop", "Earings"],
  AI_Concern: ["Takes over my job"],
};

export const ageOptions = [
  { value: "3 to 4", label: "3 to 4" },
  { value: "4 to 6", label: "4 to 6" },
  { value: "6 to 9", label: "6 to 9" },
];

export const storedIds = [
  { name: "Commitment when reading" },
  { name: "Compassion" },
  { name: "Courage" },
  { name: "Empathy" },
  { name: "Fair play" },
  { name: "Finding the bright side" },
  { name: "Helping others" },
  { name: "Honesty" },
  { name: "Generosity" },
  { name: "Positive conflict resolution" },
  { name: "Leadership" },
  { name: "Learn and practice colors" },
  { name: "Teamwork" },
  {
    name: "The importance of going to sleep early for a healthy body and mind",
  },
  {
    name:
      "The importance of keeeping a clean space for a healthy body and mind",
  },
  { name: "The positive use of humor" },
  { name: "Self-defence in the playground" },
  { name: "Sharing" },
  { name: "Treating others as you want to be treated (The Golden Rule)" },
  { name: "Learn and practice identifying animals" },
  { name: "Learn and practice identifying body parts" },
  { name: "Learn and practice identifying body colors" },
  { name: "Learn and practice identifying body foods" },
  { name: "Learn and practice identifying body letters" },
  { name: "Learn and practice identifying body objects" },
  { name: "Learn and practice identifying body shapes" },
  { name: "Learn and practice identifying body words" },
  { name: "Learn and practice numbers and counting" },
];

export const generateNoImagesPerPara = 1;