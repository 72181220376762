import Button from 'components/Elements/Button';
import React from 'react'

function Header({
    video_or_image,
    image,
    title,
    subtitle,
    description,
    icon,
    color,
    buttonText,
    buttonIcon,
    buttonColor,
    buttonPress
}) {
    const targetRef = React.createRef();

    return (
        <>
            <div className="p-0 flex items-center overflow-hidden relative bg-black min-h-screen-75">
                <div
                    style={{
                        backgroundImage: "url('" + require("assets/img/illustrations/fat_book.svg").default + "')",
                        paddingTop: "20rem",
                        paddingBottom: "20rem",
                        backgroundSize: 'cover', // Ensures the image covers the div
                        backgroundPosition: 'center', // Keeps the center of the image in the middle
                        backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                    }}
                    className="py-24 bg-cover absolute min-w-full h-full top-0 left-0"
                >

                    <div className="container mx-auto px-4">
                        {/* <div className="absolute w-full h-full bg-blueGray-900 z-1"></div> */}
                        <div className="relative text-center flex flex-wrap -mx-4 z-1">
                            <div className="mx-auto px-4 relative w-full lg:w-8/12">
                                <h1 className="text-5xl font-bold text-white leading-tight mt-0">
                                    {title} {/*  <img src={require("assets/img/illustrations/storyfave_logo.png").default} alt="Description" style={{"width": "5px", "height": "5px"}}></img> */}
                                </h1>
                                <h2 className="text-2xl text-white mb-24">{subtitle}</h2>
                                <Button color={buttonColor} size="lg" onClick={buttonPress}>
                                    <i className={buttonIcon + " mr-2"}></i>
                                    {buttonText}
                                </Button>
                            </div>
                        </div>
                    </div>

                </div>


            </div>
            <div className="bg-blueGray-50" style={{ "marginTop": "230px" }}>
                <div className="container mx-auto px-4 z-3 -mt-64 pt-20 relative w-8/12">
                    <div className="justify-center flex flex-wrap -mx-4">
                        <div className="bg-white rounded-xl shadow-lg py-8" style={{ "borderTopWidth": "thin" }}>
                            <div className="flex flex-wrap">
                                <div className="px-6 relative w-full lg:w-4/12 w-full md:w-6/12 w-6/12 border-r">
                                    <div className="text-center py-2">
                                        <h5 className="text-xl font-semibold mt-2 mb-1">
                                            Inspire Reading
                                        </h5>
                                        <iframe src="https://player.flipsnack.com?hash=QkQ1NkJFODhCN0ErMXVtZW4za3Z4bg==&p=6" width="100%" height="200" seamless="seamless" scrolling="no" frameBorder="0" allowFullScreen allow="autoplay; clipboard-read; clipboard-write"></iframe>
                                    </div>
                                </div>

                                <div className="px-6 relative w-full lg:w-4/12 w-full md:w-6/12 w-6/12 border-r">
                                    <div className="text-center py-2">
                                        <h4 className="text-5xl font-extrabold leading-tight mt-0 mb-0">
                                            15+
                                        </h4>
                                        <h5 className="text-xl font-semibold mt-2 mb-1">
                                            Unique Customizations
                                        </h5>
                                        <p className="text-blueGray-500 text-md">
                                            Mix of customized hero name, hero characteristics, key message/lesson, plot elements, and more
                                        </p>
                                        <iframe src="https://player.flipsnack.com?hash=QkQ1NkJFODhCN0ErMWpxZGV3YWppcQ==&p=20" width="100%" height="200" seamless="seamless" scrolling="no" frameBorder="0" allowFullScreen allow="autoplay; clipboard-read; clipboard-write"></iframe>
                                    </div>
                                </div>
                                <div className="px-6 relative w-full lg:w-4/12 w-full md:w-6/12 w-6/12" ref={targetRef}>
                                    <div className="text-center py-2">
                                        <h5 className="text-xl font-semibold mt-2 mb-1">
                                            With A Human Heart
                                        </h5>
                                        <iframe src="https://player.flipsnack.com?hash=QkQ1NkJFODhCN0ErejFwMmt0ZHk2eg==&p=13" width="100%" height="200" seamless="seamless" scrolling="no" frameBorder="0" allowFullScreen allow="autoplay; clipboard-read; clipboard-write"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header