import React, { useEffect } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import { useDispatch, useSelector } from "react-redux";
import ParagraphComponent from "screens/components/ParagraphComponent";
import Button from "components/Elements/Button";
import { useState } from "react";
import CustomModalComponent from "screens/components/CustomModalComponent";
import { InputText } from "primereact/inputtext";
import { useRef } from "react";
import { Toast } from "primereact/toast";
import { fetchHelper } from "screens/helpers/fetchHelpers";
import { Mention } from "primereact/mention";
import { Rating } from "primereact/rating";
import { io } from "socket.io-client";
import { setStoryTitle } from "store/storySlice";
import { addNewParatoContent } from "store/storySlice";
import { setImage } from "store/storySlice";
import { setCoverImageURL } from "store/storySlice";
import { setUserObject } from "store/userSlice";
import { setWholeStory } from "store/storySlice";
import { useNavigate } from "react-router";
import { setStory_id } from "store/storySlice";
import { addNewElements } from "store/storySlice";
import { storyInitialState } from "store/storySlice";
import { updateAllContent } from "store/storySlice";

function Story({ loadingStory, setLoadingStory, setTabIndex }) {
  const story = useSelector((state) => state.story);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [acceptStoryLoading, setAcceptStoryLoading] = useState(false);
  const toastRef = useRef(null);

  const handleSubmitModal = async () => {
    if (email.length > 0 && ratingCount > 0 && feedback.length > 0) {
      setAcceptStoryLoading(true);
      try {
        const title = story.title ? `Title: ${story.title}\n\n` : "";
        const updatedStory = story.content.map((item) => item.text);
        const fullUpdatedStory = title + updatedStory;

        let selectedImage = [];
        story.content.forEach((item, index) => {
          item.image.images.forEach((image, img_index) => {
            if (image["selected"]) {
              selectedImage.push({ [index]: img_index });
            }
          });
        });

        let storyRequestData = {
          story_text: fullUpdatedStory,
          selected_images: selectedImage,
          rating: ratingCount.toString(),
          feedback: feedback,
        };

        const { error, message } = await fetchHelper(
          `${process.env.REACT_APP_API_BASE_URL}/story/accept-story`,
          {
            method: "POST",
            mode: "cors",
            body: JSON.stringify(storyRequestData),
            headers: {
              "Content-Type": "application/json",
              Authorization: user.token,
              Story: story.id,
              Session: user.session_id,
            },
          }
        );

        if (error) {
          console.log(message);
          toastRef.current.show({
            severity: "error",
            summary: "Error",
            detail: "Internal Error! Please try again later.",
          });
        } else {
          toastRef.current.show({
            severity: "success",
            summary: "Success",
            detail: "Your Story and Feedback have been successfully submitted!",
          });
          setTabIndex(1);
        }
      } catch (e) {
        console.log(e);
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail: "Internal Error! Please try again later.",
        });
      }
      setAcceptStoryLoading(false);
    } else {
      toastRef.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please fill all fields!",
      });
    }
  };
  const [showAcceptStoryModal, setShowAcceptStoryModal] = useState(false);
  const [email, setEmail] = useState("");
  const [feedback, setFeedback] = useState("");
  const [ratingCount, setRatingCount] = useState(0);
  const navigate = useNavigate();

  const story_socket = useRef(null);

  const logout = () => {
    dispatch(
      setUserObject({
        role: "",
        isLoggedIn: false,
        fullName: "",
        token: "",
        session_id: "",
        email: "",
      })
    );
    dispatch(setWholeStory(storyInitialState));
    navigate("/");
  };

  const handleNewChar = (value) => {
    dispatch(addNewElements(value));
  };
  const handleNewCoverImage = (value) => {
    dispatch(setCoverImageURL(value));
  };
  const handleNewImage = (value) => {
    console.log(value);
    dispatch(setImage(value));
  };
  const handleNewLocation = (value) => {
    dispatch(addNewElements(value));
  };
  const handleNewObject = (value) => {
    dispatch(addNewElements(value));
  };
  const handleNewPara = (value) => {
    console.log("new Paragraph");
    dispatch(addNewParatoContent(value));
  };
  const handleNewTitle = (value) => {
    console.log("New Title");
    setLoadingStory(false);
    dispatch(setStoryTitle(value));
  };

  const handleListParagraphs = (value) => {
    console.log(value);
    dispatch(updateAllContent(value));
  };

  useEffect(() => {
    // if(socket )

    story_socket.current = io(`${process.env.REACT_APP_WS_BASE_URL}/story`, {
      query: {
        token: user.token,
        story: story.id,
      },
      transports: ["websocket"],
    });

    story_socket.current.emit('join', { story_id: story.id });

    story_socket.current.on("connected", (value) => {
      console.log("Socket connected");
      console.log(value);
      value.content !== undefined &&
        value.content.length > 0 &&
        setLoadingStory(false);
      dispatch(setStory_id(value["id"]));
      dispatch(setWholeStory(value));
    });


    story_socket.current.on("new_character", handleNewChar);
    story_socket.current.on("new_cover_image", handleNewCoverImage);
    story_socket.current.on("list_paragraphs", handleListParagraphs);
    story_socket.current.on("new_image", handleNewImage);
    story_socket.current.on("new_location", handleNewLocation);
    story_socket.current.on("new_object", handleNewObject);
    story_socket.current.on("new_para", handleNewPara);
    story_socket.current.on("new_title", handleNewTitle);
    story_socket.current.on("connect_error", (err) => {
      err.message === "Connection rejected by server" && logout();
    });

    return () => {
      story_socket.current.off("connected");
      story_socket.current.off("new_character");
      story_socket.current.off("new_cover_image");
      story_socket.current.off("new_image");
      story_socket.current.off("new_location");
      story_socket.current.off("new_object");
      story_socket.current.off("new_para");
      story_socket.current.off("new_title");
      story_socket.current.off("connect_error");
      story_socket.current.disconnect();
    };
  }, []);

  return (
    <div className="w-full flex justify-center items-center">
      {loadingStory ? (
        <ProgressSpinner />
      ) : (
        <div className="w-full flex flex-col">
          <div className="w-full flex items-center justify-center text-center">
            <h2 className="story-title">{story.title}</h2>
          </div>

          {story.content.map((item, index) => {
            return (
              <ParagraphComponent
                {...item}
                key={index}
                index={index}
                show={"both"}
              />
            );
          })}

          <div className="w-full flex items-center justify-center mt-3">
            <Button
              color={"teal"}
              onClick={() => {
                setShowAcceptStoryModal(true);
              }}
            >
              Accept Story
            </Button>
          </div>
        </div>
      )}
      <CustomModalComponent
        displayDialog={showAcceptStoryModal}
        onHide={() => {
          setShowAcceptStoryModal(false);
        }}
      >
        <div className="w-full p-3 flex items-center justify-center">
          <div className="w-1/2 flex flex-col">
            <p>Something big is coming by entering your email: </p>
            <InputText
              className="mt-3 border p-3 mb-3"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <div className="w-full h-full mb-3 flex flex-row items-center">
              <p>Please Rate us: </p>
              <Rating
                value={ratingCount}
                onChange={(e) => setRatingCount(e.value)}
                cancel={false}
                className="mb-3"
              />
            </div>

            <Mention
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              rows={5}
              cols={40}
              placeholder="Please give us your valuable feedback"
              inputClassName="rounded-lg mb-3"
            />

            {acceptStoryLoading ? (
              <ProgressSpinner />
            ) : (
              <Button color={"teal"} onClick={handleSubmitModal}>
                Submit
              </Button>
            )}
          </div>
        </div>
      </CustomModalComponent>
      <Toast ref={toastRef} />
    </div>
  );
}

export default Story;
