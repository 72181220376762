import React from "react";
import NavbarComponents from "./components/NavbarComponent";
import LoginComponent from "./components/LoginComponent";
import Footer from "./components/Footer";
import { useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { setUserObject } from "store/userSlice";
import { ProgressSpinner } from "primereact/progressspinner";
import { fetchHelper } from "./helpers/fetchHelpers";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const navigate = useNavigate();

  const handleSubmit = async () => {
    setErrorMessage("");
    try {
      if (email.length > 0 && password.length > 0) {
        setLoginLoading(true);

        const { error, message } = await fetchHelper(
          `${process.env.REACT_APP_API_BASE_URL}/user/login`,
          {
            method: "POST",
            body: JSON.stringify({ email, password }),
            headers: {
              "Content-type": "application/json",
              Accept: "application/json",
            },
          }
        );

        if (error) {
          setErrorMessage(JSON.parse(message)["message"]);
        } else {
          console.log(message);
          message['message']["isLoggedIn"] = true;
          dispatch(setUserObject(message["message"]));
          navigate("/dashboard/workspace");
          setLoginLoading(false);
        }
        setLoginLoading(false);
      } else {
        setErrorMessage("Please fill all fields.");
      }
    } catch (e) {
      console.log(e);
      setErrorMessage("Unable to login now! Please try again later.");
    }
    setLoginLoading(false);
  };

  return (
    <div className="bg-white docs-components-wrapper">
      <NavbarComponents />
      <main>
        <section className="relative w-full h-full py-20">
          <div
            className="absolute top-0 w-full h-full bg-blueGray-900 bg-no-repeat bg-full"
            style={{
              backgroundImage: `url(${
                require("assets/img/register_bg_2.png").default
              })`,
            }}
          />
          <div className="container mx-auto px-4 h-full">
            <div className="flex content-center items-center justify-center h-full">
              <div className="w-full lg:w-4/12 px-4">
                <LoginComponent
                  title={"Sign in with"}
                  subtitle={"Or sign in with credentials"}
                  socials={[
                    { icon: "google", button: { href: "#" } },
                    { icon: "facebook", button: { href: "#" } },
                    { icon: "twitter", button: { href: "#" } },
                  ]}
                  buttons={[
                    {
                      fullWidth: true,
                      color: "dark",
                      children: loginLoading ? (
                        <ProgressSpinner
                          style={{ width: "50px", height: "50px" }}
                          strokeWidth="2"
                        />
                      ) : (
                        "Sign in"
                      ),
                      type: "submit",
                      onClick: (e) => {
                        e.preventDefault();
                        handleSubmit();
                      },
                    },
                    {
                      fullWidth: true,
                      color: "teal",
                      children: "Create Account",
                      type: "button",
                      onClick: (e) => {
                        navigate("/register");
                      },
                    },
                  ]}
                  forgotPassword={{}}
                  createAccount={{ to: "/register" }}
                  checkbox={{
                    label: "Remember Me",
                  }}
                  errorMessage={errorMessage}
                  inputs={[
                    {
                      label: "Email",
                      input: {
                        type: "email",
                        placeholder: "Email",
                        value: email,
                        onChange: (e) => setEmail(e.target.value),
                      },
                    },
                    {
                      label: "Password",
                      input: {
                        type: "password",
                        placeholder: "Password",
                        value: password,
                        onChange: (e) => setPassword(e.target.value),
                      },
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </main>
    </div>
  );
};

export default Login;
