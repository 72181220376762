import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    role: '',
    isLoggedIn: false,
    fullName: '',
    token: '',
    session_id: '',
    email: ''
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setRole: (state, action) => {
            state.role = action.payload;
        },
        setIsLoggedIn: (state, action) => {
            state.isLoggedIn = action.payload
        },
        setFullName: (state, action) => {
            state.fullName = action.payload
        },
        setToken: (state, action) => {
            state.token = action.payload
        },
        setSession_id: (state, action) => {
            state.session_id = action.payload
        },
        setUserObject: (state, action) => {
            state.role = action.payload.role
            state.isLoggedIn = action.payload.isLoggedIn
            state.fullName = action.payload.fullName
            state.token = action.payload.token
            state.session_id = action.payload.session_id
            state.email = action.payload.email
        }
    }
})

export const { setRole, setIsLoggedIn, setFullName, setToken, setUserObject, setSession_id } = userSlice.actions
export default userSlice.reducer