import { ProgressSpinner } from "primereact/progressspinner";
import React from "react";
import { useSelector } from "react-redux";

const PDFStory = ({ loadingStory, setLoadingStory, setTabIndex }) => {
  const story = useSelector((state) => state.story);

  if (loadingStory) {
    return <ProgressSpinner />;
  }

  return (
    <div
      className="w-full p-2 bg-white flex flex-col items-center"
      id="preview_div"
    >      
      <div
        className="border rounded-lg mb-4 flex relative w-full"
        // style={{ width: "1080px", height: "640px"}} 
        id="title"       
      >
        <div className="relative w-full">
          <img
            src={`${
              process.env.REACT_APP_API_BASE_URL
            }/story/get-image?url=${encodeURIComponent(story.cover_url)}`}
            className="w-full h-full rounded-lg"
          />

          <div className="gradient-overlay"></div>
        </div>

        <div className="absolute bottom-0 p-4 w-full">
          <p
            className="text-white font-semibold font-[24px] text-center font-hannotate"
            style={{ fontSize: "4em" }}
          >
            {story.title.replace("Title: ", "")}
          </p>
        </div>
      </div>
      {story.content.map((item, index) => {
        return (
          <div
            className="border rounded-lg mb-4 flex relative w-full"
            // style={{ width: "1080px", height: "640px"}}
            key={index}
            id={`id_${index}`}
          >
            <div className="relative w-full">
              {item.images.map((image_item) => {
                if (image_item["selected"]) {
                  return (
                    <img
                      src={`${
                        process.env.REACT_APP_API_BASE_URL
                      }/story/get-image?url=${encodeURIComponent(
                        image_item.url
                      )}`}
                      className="w-full h-full rounded-lg"
                    />
                  );
                }
              })}
              <div className="gradient-overlay"></div>
            </div>

            <div className="absolute bottom-0 p-4 w-full">
              <p
                className="text-white font-semibold font-[24px] text-center font-hannotate"
                style={{ fontSize: "1.5em" }}
              >
                {item.text}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PDFStory;
