import Button from "components/Elements/Button";
import React from "react";

const StoryCardComponent = ({ bgImage, title, description, buttons = [] }) => {
  return (
    <>
      <div className="overflow-hidden rounded-lg relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg h-400-px transition-all duration-150 ease-in-out hover:transform hover:scale-110 group">
        <div
          className="absolute rounded-lg w-full h-full bg-50-center bg-cover transition-all duration-1000 ease-in-out group-hover:transform group-hover:scale-110"
          style={{
            backgroundImage: "url(" + bgImage + ")",
            backfaceVisibility: "hidden",
          }}
        />
        <div className="absolute rounded-lg w-full h-full bg-black opacity-50"></div>
        <div className="p-10 flex h-full items-end z-1">
          <div>
            <h1 className="text-2xl font-semibold mt-0 mb-2 text-white">
              {title}
            </h1>
            <p className="text-white opacity-75 text-lg">{description}</p>
            <div className="w-full flex flex-row flex-wrap gap-2">
              {buttons.map((item) => {
                return <Button {...item} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StoryCardComponent;
