import Button from "components/Elements/Button";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import "@pqina/pintura/pintura.css";
import { PinturaEditor } from "@pqina/react-pintura";

import { Image as PrimeImage } from "primereact/image";
import { Mention } from "primereact/mention";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateParaText } from "store/storySlice";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { setImage } from "store/storySlice";
import { fetchHelper } from "screens/helpers/fetchHelpers";
import { updateSelectedImage } from "store/storySlice";

import CustomModalComponent from "./CustomModalComponent";

// Import the editor functionality
import {
  // Import the default image reader and writer
  createDefaultImageReader,
  createDefaultImageWriter,

  // The method used to register the plugins
  setPlugins,

  // The plugins we want to use
  plugin_crop,
  plugin_finetune,
  plugin_resize,

  // The user interface and plugin locale objects
  locale_en_gb,
  plugin_crop_locale_en_gb,
  plugin_finetune_locale_en_gb,
  plugin_resize_locale_en_gb,

  // Because we use the annotate plugin we also need
  // to import the markup editor locale and the shape preprocessor
  markup_editor_locale_en_gb,
  createDefaultShapePreprocessor,

  // Import the default configuration for the markup editor and finetune plugins
  markup_editor_defaults,
  plugin_finetune_defaults,
} from "@pqina/pintura";
import { updateImageUrl } from "store/storySlice";
import { TypeAnimation } from "react-type-animation";

setPlugins(plugin_crop, plugin_finetune, plugin_resize);

// import { getEditorDefaults } from "@pqina/pintura";

function ParagraphComponent({ index, id, text, images, show = "both" }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const toastRef = useRef(null);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const [editText, setEditText] = useState(false);
  const [editGenerateImage, setEditGenerateImage] = useState(false);

  const [currentText, setCurrentText] = useState(text);
  const [currentImages, setCurrentImages] = useState(images);
  const [imagePrompt, setImagePrompt] = useState();
  const [imagePromptLoading, setImagePromptLoading] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const [selectedStyleId, setSelectedStyleId] = useState();

  const [showImageEditor, setShowImageEditor] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const [imageToUpdate, setImageToUpdate] = useState();

  const handleUpdateText = () => {
    let data = {
      index,
      text: currentText,
    };

    dispatch(updateParaText(data));
    setEditText(false);
  };

  const handleGenerateNewImage = async () => {
    let imageRequestData = {
      paragraph_id: id,
      prompt: imagePrompt,
      style_id: selectedStyleId
    };
    // console.log(imageRequestData)
    toastRef.current.show({
      severity: "info",
      summary: "Info",
      detail:
        "New Image Generation Started! We will notify you once generated!",
    });
    setImagePromptLoading(true);

    const { error, message } = await fetchHelper(
      `${process.env.REACT_APP_API_BASE_URL}/story/create-image`,
      {
        method: "POST",
        body: JSON.stringify(imageRequestData),
        headers: {
          "Content-Type": "application/json",
          Authorization: user.token,
        },
      }
    );

    if (!error) {
      message["selected"] = false;
      dispatch(setImage(message));

      toastRef.current.show({
        severity: "success",
        summary: "Success",
        detail: "New Image Generated!",
      });
      setEditGenerateImage(false);
      setImagePromptLoading(false);
    } else {
      try {
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail: JSON.parse(message)["message"],
        });
      } catch (e) {
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail: "Unable to Generate Image! Please try again.",
        });
      }
      setEditGenerateImage(false);
      setImagePromptLoading(false);
    }
  };

  const blobToBase64 = (blob) => {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  };

  const editorConfig = {
    // This will read the image data (required)
    imageReader: createDefaultImageReader(),

    // This will write the output image
    imageWriter: createDefaultImageWriter({
      orientImage: true,
      copyImageHead: false,
      mimeType: "image/jpeg",
      quality: 1,
      format: "file",
      store: async function (imageData) {
        var data = {
          para: index,
          image_url: imageToUpdate,
          image: await blobToBase64(imageData.blob),
        };

        await dispatch(updateImageUrl(data));
        setShowImageEditor(false);
        return true;
      },
    }),

    // The markup editor default options, tools, shape style controls
    ...markup_editor_defaults,

    // The finetune util controls
    ...plugin_finetune_defaults,

    // This handles complex shapes like arrows / frames
    shapePreprocessor: createDefaultShapePreprocessor(),

    // The icons and labels to use in the user interface (required)
    locale: {
      ...locale_en_gb,
      ...plugin_crop_locale_en_gb,
      ...plugin_finetune_locale_en_gb,
      ...plugin_resize_locale_en_gb,
      ...markup_editor_locale_en_gb,
    },
  };

  useEffect(() => {    
    setCurrentText(text);
    setCurrentImages(images);
    if (!images.loading) {
      setImagePrompt(images.length > 0 ? images[0].prompt : "");
      setSelectedImage(images.length > 0 ? images[0].url : "");
    }

    return () => {};
  }, [text, images]);

  const checkBase64 = (str) => {
    const base64Pattern = /^data:image\/[a-zA-Z]+;base64,([A-Za-z0-9+/]+={0,2})$/;
    return base64Pattern.test(str);
  };

  // const editorConfig = getEditorDefaults(eConfig);

  useEffect(() => {
    if (images !== undefined) {
      setImagePrompt(images.length > 0 ? images[currentImageIndex].prompt : "");
      setSelectedImage(images.length > 0 ? images[currentImageIndex].url : "");

      // setSelectedImage(URL.createObjectURL(image.images[currentImageIndex].url))
    }
  }, [currentImageIndex]);

  return (
    <div className="story-container story-paragraph-container flex flex-col items-center">
      <div className="story-page flex flex-col items-center w-full">
        {show === "text" && (
          <div className="w-full mb-2 flex flex-col justify-center items-center">
            {editText ? (
              <Mention
                value={currentText}
                onChange={(e) => {
                  setCurrentText(e.currentTarget.value);
                }}
                name="text"
                rows={5}
                cols={100}
                className="w-full flex items-center justify-center mb-3"
              />
            ) : (
              <div className="w-full flex items-center justify-center">{text}</div>
            )}
            <div className="w-full flex flex-row space-x-3 items-center justify-center">
              <Button
                color={editText && "teal"}
                onClick={() => {
                  if (editText) {
                    handleUpdateText();
                  } else {
                    setEditText(true);
                  }
                }}
              >
                {editText ? "Update Text" : "edit Text"}
              </Button>
              {editText && (
                <Button
                  onClick={() => {
                    setEditText(false);
                  }}
                >
                  Cancel
                </Button>
              )}
            </div>
          </div>
        )}
        {(show === "both" || show === "image") && (
          <div className={`w-full flex items-center flex-col mb-4`}>
            {images.length == 0 ? (
              <div className="w-full flex flex-col items-center justify-center">
                <div className="mb-3">
                  <p className="font-serif text-center">{text}</p>
                  {/* <TypeAnimation cursor={false} sequence={[text, 1000]} speed={50/parseInt(index)} className="font-serif text-center" /> */}
                </div>
                <div
                  className="bg-teal animate-pulse rounded-2xl"
                  style={{ width: 600, height: 200 }}
                />
                {/* <Skeleton
                  baseColor="#c0f2f3"
                  height={200}
                  width={600}
                  highlightColor="#fff"
                /> */}
              </div>
            ) : (
              <div className="w-full">
                <Carousel
                  containerClass="mb-4"
                  responsive={responsive}
                  showDots={true}
                  afterChange={(nextSlide, { currentSlide, onMove }) => {
                    console.log(nextSlide, currentSlide, onMove);
                    setCurrentImageIndex(currentSlide);
                    dispatch(
                      updateSelectedImage({ para: index, image: currentSlide })
                    );
                  }}
                >
                  {currentImages.map((item) => {
                    return (
                      <div className="w-full p-2 flex items-center justify-center flex-col relative">
                        <div className="w-full relative flex flex-col items-center justify-center">
                          <PrimeImage
                            preview
                            width={"100%"}
                            src={item.url}
                            alt="Image"
                            loading={"eager"}
                            imageClassName="rounded-lg"
                          />
                          {show === "both" && (
                            <div
                              className="gradient-overlay cursor-pointer"
                              onClick={() => {
                                setImageToUpdate(item.url);
                                setShowImageEditor(true);
                              }}
                            ></div>
                          )}
                        </div>
                        {show === "both" && (
                          <div className="absolute bottom-0 w-3/4 items-center p-6">
                            <p className="text-white font-semibold text-center text-xl font-hannotate">
                              {text}
                            </p>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </Carousel>
                {editGenerateImage && (
                  <div className="w-full flex flex-col items-center justify-center">
                    <Mention
                      value={imagePrompt}
                      onChange={(e) => setImagePrompt(e.currentTarget.value)}
                      rows={4}
                      cols={75}
                      disabled={imagePromptLoading}
                      autoResize
                    />
                    <div className="style-preset-dropdown mt-4">
                      <label htmlFor="stylePreset">Additional style:</label>
                      <select
                        value={selectedStyleId || "None"}
                        name="stylePreset"
                        id="stylePreset"
                        className="px-4 py-2 ml-2 rounded-full border text-sm font-medium bg-light-teal rounded-input-long"
                        onChange={(e) =>
                          setSelectedStyleId(e.currentTarget.value)
                        }
                      >
                        <option value="" disabled>
                          Select default style
                        </option>
                        <option value="2">Watercolor</option>
                        <option value="3">Impressionism</option>
                        <option value="4">Whimsical</option>
                        {user.role === "admin" && (
                          <>
                            <option value="fantasy-art">
                              Fantasy Art (Experimental)
                            </option>
                            <option value="cinematic">
                              Cinematic (Experimental)
                            </option>
                            <option value="isometric">
                              Isometric (Experimental)
                            </option>
                            <option value="neon-punk">
                              Neon Punk (Experimental)
                            </option>
                            <option value="comic-book">
                              Comic Book (Experimental)
                            </option>
                          </>
                        )}
                        <option value="none">None</option>
                      </select>
                    </div>
                    <div className="mt-3">
                      <Button
                        color={!imagePromptLoading && "teal"}
                        disabled={imagePromptLoading}
                        onClick={handleGenerateNewImage}
                      >
                        {imagePromptLoading ? "Generating..." : "Submit"}
                      </Button>
                      {!imagePromptLoading && (
                        <Button
                          onClick={() => {
                            setEditGenerateImage(false);
                          }}
                          disabled={imagePromptLoading}
                        >
                          Cancel
                        </Button>
                      )}
                    </div>
                  </div>
                )}
                {editText && (
                  <div className="w-full flex flex-col items-center">
                    <Mention
                      value={currentText}
                      onChange={(e) => {
                        setCurrentText(e.currentTarget.value);
                      }}
                      name="text"
                      rows={5}
                      cols={100}
                      className="w-full flex items-center justify-center mb-3"
                      autoResize
                    />
                    <div className="mt-3">
                      <Button color={"teal"} onClick={handleUpdateText}>
                        Update Text
                      </Button>
                      <Button
                        onClick={() => {
                          setEditText(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                )}
                {!editText && !editGenerateImage && user.role === 'admin' && (
                  <div className="w-full flex flex-row items-center justify-around">
                    <div
                      className={`${
                        show === "both" ? "w-1/2" : "w-full"
                      } flex items-center justify-center flex-row`}
                    >
                      <Button
                        onClick={() => {
                          setEditGenerateImage(true);
                        }}
                        color={""}
                      >
                        Generate a New Image
                      </Button>
                    </div>
                    {show === "both" && (
                      <div className="w-1/2 flex flex-row space-x-3 items-center justify-center">
                        <Button
                          color={""}
                          onClick={() => {
                            setEditText(true);
                          }}
                        >
                          {editText ? "Update Text" : "edit Text"}
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      <CustomModalComponent
        displayDialog={showImageEditor}
        onHide={() => {
          setShowImageEditor(false);
        }}
        header="Image Editor"
      >
        <div className="w-full p-2" style={{ height: "600px" }}>
          <PinturaEditor
            {...editorConfig}
            src={
              checkBase64(selectedImage)
                ? selectedImage
                : `${
                    process.env.REACT_APP_API_BASE_URL
                  }/story/get-image?url=${encodeURIComponent(selectedImage)}`
            }
          />
        </div>
      </CustomModalComponent>
      <Toast ref={toastRef} />
    </div>
  );
}

export default ParagraphComponent;
