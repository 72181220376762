import { Dialog } from "primereact/dialog";
import React from "react";

const CustomModalComponent = ({ displayDialog, onHide, children,header="Accept Story", custom_color_class="bg-white" }) => {
  return (
    <Dialog
      className={`shadow-lg ${custom_color_class} w-6/12 rounded-md p-8 flex flex-col`}
      header={header}
      visible={displayDialog}
      modal
      onHide={onHide}
      draggable={false}       

    >
        {children}
    </Dialog>
  );
};

export default CustomModalComponent;
