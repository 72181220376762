import Button from "components/Elements/Button";
import { Image } from "primereact/image";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import StoryCardComponent from "screens/components/StoryCardComponent";
import { savedStories } from "screens/constants/StoriesConstant";
import { fetchHelper } from "screens/helpers/fetchHelpers";
import { io } from "socket.io-client";
import { storyInitialState } from "store/storySlice";
import { setStory_id } from "store/storySlice";
import { setWholeStory } from "store/storySlice";
import { setUserObject } from "store/userSlice";

const GetStory = ({ setLoadingStory, setTabIndex, handle, setShowPreview }) => {

  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const toastRef = useRef();

  const [stories, setStories] = useState([]);
  const [searchStoryText, setSearchStoryText] = useState("");
  const [isSearching, setIsSearching] = useState(false);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);  

  const [getStoriesLoading, setGetStoriesLoading] = useState(true)

  useEffect(() => {
    window.addEventListener("resize", () => {
      setScreenWidth(window.innerWidth);
    });
    return () =>
      window.removeEventListener("resize", () => {
        setScreenWidth(window.innerWidth);
      });
  }, []);

  const handleLogout = async () => {
    dispatch(
      setUserObject({
        role: "",
        isLoggedIn: false,
        fullName: "",
        token: "",
        session_id: "",
        email: "",
      })
    );
    dispatch(
      setWholeStory(storyInitialState)
    );
    navigate("/");
  };

  const getStories = async () => {
    setGetStoriesLoading(true)
    const {error, message, status} = await fetchHelper(`${process.env.REACT_APP_API_BASE_URL}/user/list-stories?page=${currentPage}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: user.token,
      },
    })

    if(error){
      if(status === 401){
        handleLogout()
      }else{
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail: message,
        });
      }
    }else{
      setCurrentPage(message['current_page'])
      setTotalPages(message['pages'])
      setStories(message['stories'])
    }
    setGetStoriesLoading(false)
  }

  useEffect(() => {
    getStories()
  }, [currentPage]);



  return (
    <div className="w-full flex flex-col">
      {/* Heading div */}
      <div className="w-full p-2 flex flex-row items-center justify-between">
        <div className="">
          <h1 className="text-2xl font-bold">STORIES</h1>
        </div>
        <div className="flex flex-row gap-2 items-center">
          <InputText
            className="border p-2"
            placeholder="Search Story"
            value={searchStoryText}
            onChange={(e) => setSearchStoryText(e.currentTarget.value)}
          />
          {isSearching && (
            <i
              className="pi pi-filter-slash text-2xl cursor-pointer"
              onClick={() => {}}
            />
          )}
        </div>
      </div>
      {/* stories div */}
      <div
        className={`w-full ${
          screenWidth >= 1024 ? "flex-row" : "flex-col"
        } flex flex-wrap`}
        style={{ gap: "5rem" }}
      >
        {getStoriesLoading ? (
          <ProgressSpinner />
        ) : stories.length > 0 ? (
          stories.map((item, index) => {
            if (item.cover_url != "") {
              return (
                <div
                  className={`flex ${screenWidth >= 1024 ? "w-1/5" : "w-full"}`}
                >
                  <StoryCardComponent
                    bgImage={item.cover_url}
                    title={item.title.replace("Title: ", "")}
                    description={""}
                    buttons={[
                      {
                        color: "teal",
                        onClick: () => {
                          console.log("Preview: ", item);
                          dispatch(setStory_id(item.id));
                          dispatch(setWholeStory(item));
                          setLoadingStory(false);
                          setShowPreview(true);
                          handle.enter();
                        },
                        children: "Preview",
                        size: "sm",
                      },
                      {
                        color: "dark",
                        onClick: () => {
                          console.log(item);
                          dispatch(setStory_id(item["id"]));
                          dispatch(setWholeStory(item));
                          setTabIndex(2);
                          setLoadingStory(false);
                        },
                        children: "Edit story",
                        size: "sm",
                      },
                    ]}
                  />
                </div>
              );
            }
          })
        ) : (
          <p>No Stories created by You</p>
        )}
      </div>
      <div className="w-full flex flex-row items-center justify-center mt-4">
        <i
          className="pi pi-angle-double-left text-2xl cursor-pointer"
          onClick={() => {
            !getStoriesLoading && setCurrentPage(1);
          }}
        />
        <i
          className="pi pi-angle-left text-2xl cursor-pointer"
          onClick={() => {
            currentPage != 1 && setCurrentPage(currentPage - 1);
          }}
        />
        <div
          className="p-2 rounded-lg mr-1"
          style={{ backgroundColor: "lightgray", opacity: 0.8, color: "#fff" }}
        >
          {currentPage}
        </div>
        <p className=""> / {totalPages}</p>
        <i
          className="pi pi-angle-right text-2xl cursor-pointer"
          onClick={() => {
            currentPage != totalPages && setCurrentPage(currentPage + 1);
          }}
        />
        <i
          className="pi pi-angle-double-right text-2xl cursor-pointer"
          onClick={() => {
            !getStoriesLoading && setCurrentPage(totalPages);
          }}
        />
      </div>
      <Toast ref={toastRef} />
    </div>
  );
};

export default GetStory;
