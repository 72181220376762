import React from "react";
import NavbarComponents from "./components/NavbarComponent";
import { useState } from "react";
import RegisterComponent from "./components/RegisterComponent";
import Footer from "./components/Footer";
import { ProgressSpinner } from "primereact/progressspinner";
import { fetchHelper } from "./helpers/fetchHelpers";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { useNavigate } from "react-router";

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState();
  const [fullName, setFullName] = useState();
  const [specialToken, setSpecialToken] = useState("")

  const [registerLoading, setRegisterLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const toastRef = useRef();

  const navigate = useNavigate()

  const handleSubmit = async () => {
    setRegisterLoading(true);
    const { error, message } = await fetchHelper(
      `${process.env.REACT_APP_API_BASE_URL}/user/register`,
      {
        method: "POST",
        body: JSON.stringify({ email, password, fullName, specialToken }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (!error) {
      toastRef.current.show({
        severity: "success",
        summary: "Success",
        detail: "User registered successfully! You can login now.",
      });
    } else {
      try {
        setErrorMessage(JSON.parse(message)['message']);
      } catch (e) {
        console.log(e);
        setErrorMessage("Please try again later.");
      }
    }
    setRegisterLoading(false);
  };

  return (
    <div className="bg-white docs-components-wrapper">
      <NavbarComponents />
      <main>
        <section className="relative w-full h-full py-20">
          <div
            className="absolute top-0 w-full h-full bg-blueGray-900 bg-no-repeat bg-full"
            style={{
              backgroundImage: `url(${
                require("assets/img/register_bg_2.png").default
              })`,
            }}
          />
          <div className="container mx-auto px-4 h-full">
            <div className="flex content-center items-center justify-center h-full">
              <div className="w-full lg:w-4/12 px-4">
                <RegisterComponent
                  title={"Register"}
                  subtitle={""}
                  socials={[]}
                  buttons={[
                    {
                      fullWidth: true,
                      color: "dark",
                      children: registerLoading ? (
                        <ProgressSpinner
                          style={{ width: "50px", height: "50px" }}
                          strokeWidth="2"
                        />
                      ) : (
                        "Register"
                      ),
                      type: "submit",
                      onClick: (e) => {
                        e.preventDefault();
                        handleSubmit();
                      },
                    },
                    {
                      fullWidth: true,
                      color: "teal",
                      children: "Login",
                      type: "button",
                      onClick: (e) => {
                        navigate("/login")
                      },
                    }
                  ]}
                  forgotPassword={{}}
                  createAccount={{}}
                  errorMessage={errorMessage}
                  inputs={[
                    {
                      label: "Full Name",
                      input: {
                        type: "text",
                        placeholder: "Full Name",
                        value: fullName,
                        onChange: (e) => setFullName(e.target.value),
                      },
                    },
                    {
                      label: "Email",
                      input: {
                        type: "email",
                        placeholder: "Email",
                        value: email,
                        onChange: (e) => setEmail(e.target.value),
                      },
                    },
                    {
                      label: "Password",
                      input: {
                        type: "password",
                        placeholder: "Password",
                        value: password,
                        onChange: (e) => setPassword(e.target.value),
                      },
                    },
                    {
                      label: "Passcode",
                      input: {
                        type: "password",
                        placeholder: "Passcode",
                        value: specialToken,
                        onChange: (e) => setSpecialToken(e.target.value),
                      },
                    },                    
                  ]}
                />
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </main>
      <Toast ref={toastRef} />
    </div>
  );
}

export default Register;
