import React, { useEffect, useState } from 'react'
import YouTube from 'react-youtube';

function VideoLoader({ videoId }) {
    const [opts, setOpts] = useState({
        height: '390', // Default height
        width: '640',  // Default width
        playerVars: {
            autoplay: 1,
            mute: 1,
            loop: 1,
            playlist: videoId,
        },
    });

    // Dynamically adjust video size based on the viewport
    useEffect(() => {
        function handleResize() {
            const { innerWidth: width, innerHeight: height } = window;
            // Example logic to adjust video size
            const newWidth = width > 640 ? 640 : width - 20;
            const newHeight = (newWidth * 9) / 16; // Maintain a 16:9 aspect ratio
            setOpts((prevOpts) => ({
                ...prevOpts,
                width: newWidth.toString(),
                height: newHeight.toString(),
            }));
        }

        window.addEventListener('resize', handleResize);
        handleResize(); // Call it initially

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return <YouTube videoId={videoId} opts={opts} />;
}

export default VideoLoader