import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useOutlet } from "react-router";

const ProtectedRoute = () => {
  const { isLoggedIn } = useSelector((state) => state.user);
  const outlet = useOutlet();

  console.log("Protected route: ", isLoggedIn);

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  return <div className="w-full h-full">{outlet}</div>;
};

export default ProtectedRoute;
