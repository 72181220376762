import Button from "components/Elements/Button";
import React, { useState } from "react";

function IterateUpload() {
  var singleFileObj = [];
  var singleFileArray = [];

  const [singleFile, setSingleFile] = useState([]);

  const uploadSingleFiles = (e) => {
    singleFileObj.push(e.target.files);
    singleFileArray.push(URL.createObjectURL(singleFileObj[0][0]));

    setSingleFile([...singleFile, singleFileArray]);
  };

  const removeImage = (index) => {
    setSingleFile([
      ...singleFile.slice(0, index),
      ...singleFile.slice(index + 1, singleFile.length),
    ]);
  };

  return (
    <div className="w-full flex items-center justify-center mt-4">
      <div className="w-full flex flex-row items-center justify-center gap-2">
        {singleFile.length !== 0 &&
          singleFile.map((url, index) => {
            return (
              <div key={url} className="flex w-1/6 items-center justify-center">
                <div className="img-block bg-teal">
                  <img className="img-block" src={url} alt="..." />
                  <span
                    className="remove_img"
                    onClick={() => removeImage(index)}
                  >
                    X
                  </span>
                </div>
              </div>
            );
          })}
        {singleFile.length > 4 ? null : (
          <div className="upload-btn-wrapper">
            <button>+</button>
            <input type="file" name="myfile" onChange={uploadSingleFiles} />
          </div>
        )}
      </div>
    </div>
  );
}

export default IterateUpload;
