import React, { useState } from 'react'
import VideoLoader from './VideoLoader'
import Button from 'components/Elements/Button'
import ModalComponent from './ModalComponent'

function VideoCard({displayDialog, setDisplayDialog}) {    

    return (
        <div className='rounded-lg p-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl z-2'>
            <div className='video-responsive'>
                <VideoLoader videoId={"3g8y7FOwPU4"} />
            </div>
            <div className='w-full flex justify-center'>
                <Button color={'teal'} style={{ "marginTop": "27px" }} size={'lg'} onClick={() => { setDisplayDialog(true) }}>
                    Start Now
                </Button>
            </div>
            <ModalComponent displayDialog={displayDialog} setDisplayDialog={setDisplayDialog} />
        </div>
    )
}

export default VideoCard