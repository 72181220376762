import Button from "components/Elements/Button";
import Checkbox from "components/Elements/Checkbox";
import Input from "components/Elements/Input";
import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { fetchHelper } from "screens/helpers/fetchHelpers";
import { setRole } from "store/userSlice";

function ModalComponent({ displayDialog, setDisplayDialog }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [userDescriptor, setUserDescriptor] = useState([]);
  const [interests, setInterests] = useState([]);

  // Function to handle changes in the email input
  const [earlyAccessEmail, setEarlyAccessEmail] = useState("");

  const validateEmail = (email) => {
    const regex = /\S+@\S+\.\S+/; // Simple email regex pattern
    return regex.test(email);
  };
  const handleEmailChange = (event) => {
    const email = event.target.value;
    setEarlyAccessEmail(email);
  };

  const handleEarlyAccessSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    setErrorMessage("");

    //if (!isEmailValid || !hasCheckboxSelected) {
    if (!validateEmail(earlyAccessEmail)) {
      setErrorMessage("Please enter a valid email and make a selection.");
      return; // Prevent form submission
    }

    // Prepare the data to be sent
    const earlyAccessData = {
      customer_description: userDescriptor.join(", ") + interests.join(", "),
      email: earlyAccessEmail,
    };

    try {
      const { error, message } = await fetchHelper(
        `${process.env.REACT_APP_API_BASE_URL}/auth/early-access`,
        {
          method: "POST",
          body: JSON.stringify(earlyAccessData),
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (!error) {
        setSubmitSuccess(true);
      } else {
        console.log(message);
        setErrorMessage("Servers working hard. Please try again later :)");
      }
    } catch (e) {
      console.log(e);
      setErrorMessage("Servers working hard. Please try again later :)");
    }
  };

  const [errorMessage, setErrorMessage] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState(false);

  return (
    <Dialog
      className="shadow-lg bg-white rounded-md p-3"
      header="Thank you for your interest! "
      visible={displayDialog}
      modal
      onHide={() => setDisplayDialog(false)}
    >
      <h2>
        Unfortunately, we are <b>sold out this week.</b>
      </h2>
      <h2>
        <b>But you can reserve your spot below! </b>
      </h2>
      <br></br>
      <h2>We'll reach out to you in order of reservation. 🔖</h2>
      <br></br>
      <div>
        {/* <Checkbox {...props} /> */}
        <>
          <h2>I am a:</h2>
          <div>
            <Checkbox
              label="relative or friend of the child"
              name="relativeOrFriendOfChild"
              onChange={(e) =>
                e.currentTarget.checked
                  ? setUserDescriptor([
                      ...userDescriptor,
                      "relativeOrFriendOfChild",
                    ])
                  : setUserDescriptor(
                      userDescriptor.filter(
                        (item) => item != "relativeOrFriendOfChild"
                      )
                    )
              }
            />
            <div></div>
            <Checkbox
              label="aspiring children's book author"
              name="aspiringAuthors"
              onChange={(e) =>
                e.currentTarget.checked
                  ? setUserDescriptor([...userDescriptor, "aspiringAuthors"])
                  : setUserDescriptor(
                      userDescriptor.filter((item) => item != "aspiringAuthors")
                    )
              }
            />
          </div>
          <br></br>
          <h2>I want to:</h2>
          <div>
            <Checkbox
              label="only create one or a few copies"
              name="creatingBookOnly"
              onChange={(e) =>
                e.currentTarget.checked
                  ? setInterests([...interests, "aspiringAuthors"])
                  : setInterests(
                      interests.filter((item) => item != "aspiringAuthors")
                    )
              }
            />
            <div></div>
            <Checkbox
              label="sell this book online and/or stores"
              name="sellingBookFuture"
              onChange={(e) =>
                e.currentTarget.checked
                  ? setInterests([...interests, "sellingBookFuture"])
                  : setInterests(
                      interests.filter((item) => item != "sellingBookFuture")
                    )
              }
            />
          </div>
          <br></br>
          <h2>Email:</h2>
          <Input
            type="email"
            name="earlyAccessEmail"
            value={earlyAccessEmail}
            onChange={handleEmailChange}
            placeholder="Email to get notified!"
          />
          <br></br>
          <Button
            color={"teal"}
            label="Submit"
            onClick={handleEarlyAccessSubmit}
          >
            Reserve Your Spot
          </Button>
          {errorMessage && (
            <div style={{ marginTop: "10px", color: "red" }}>
              {errorMessage}
            </div>
          )}
          {submitSuccess && (
            <div style={{ marginTop: "10px", color: "green" }}>
              Congratulations! Your spot has been reserved!
            </div>
          )}
          <br></br>
        </>
        <br></br>
        <br></br>
        <hr></hr>
        <hr></hr>
        <br></br>
        <h1>Otherwise, if you received an email... Congratulations!</h1>
        <br></br>
        {/* <Input
                    type="password"
                    value={passcode}
                    onChange={(e) => setPasscode(e.target.value)}
                    placeholder="Enter access code"
                />
                <br></br>
                <Button color={"lightDark"} label="Submit" onClick={handlePasscodeSubmit}>Enter Access Code</Button>
                {passcodeError && <p style={{ color: 'red' }}><br></br><p> </p>{passcodeError}</p>} */}
        <Button
          color={"teal"}
          onClick={() => {
            navigate("/login");
          }}
        >
          Login
        </Button>
      </div>
    </Dialog>
  );
}

export default ModalComponent;
