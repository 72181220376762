import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Toast } from "reactstrap";
import { fetchHelper } from "screens/helpers/fetchHelpers";
import { InputText } from "primereact/inputtext";
import Button from "components/Elements/Button";

function UserSettings() {
  const user = useSelector((state) => state.user);

  const [settings, setSettings] = useState({
    number_paragraphs_per_story: 0,
    number_of_images_per_paragraph: 0,
  });
  const [settingsLoading, setSettingsLoading] = useState(true);

  const [submitLoading, setSubmitLoading] = useState(false);

  const toastRef = useRef(null);

  const getSettings = async () => {
    setSettingsLoading(true);
    try {
      const { error, message } = await fetchHelper(
        `${process.env.REACT_APP_API_BASE_URL}/user/get-settings`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: user.token,
          },
        }
      );

      if (error) {
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail: message,
        });
      } else {
        console.log(message);
        setSettings(message);
      }
    } catch (e) {
      console.log(e);
    }
    setSettingsLoading(false);
  };

  useEffect(() => {
    getSettings();

    return () => {};
  }, []);

  const updateSettings = async () => {
    if (settings.number_of_images_per_paragraph > 5) {
      toastRef.current.show({
        severity: "error",
        summary: "Error",
        detail: "This much number of images not allowed",
      });
      return;
    }

    if (settings.number_paragraphs_per_story > 16) {
      toastRef.current.show({
        severity: "error",
        summary: "Error",
        detail: "This much Paragraphs are not allowed",
      });
      return
    }

    setSubmitLoading(true);
    try {
      const { error, message } = await fetchHelper(
        `${process.env.REACT_APP_API_BASE_URL}/user/update-settings`,
        {
          method: "POST",
          body: JSON.stringify(settings),
          headers: {
            "Content-Type": "application/json",
            Authorization: user.token,
          },
        }
      );

      if (error) {
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail: message,
        });
      } else {
        getSettings();
      }
    } catch (e) {
      console.log(e);
    }
    setSubmitLoading(false);
  };

  return (
    <div className="w-full flex flex-row mt-4 justify-between">
      <div className="w-full p-2 flex flex-col">
        {settingsLoading ? (
          <ProgressSpinner />
        ) : (
          <div className="w-1/2 p-2 flex flex-col gap-2">
            <div className="w-1/2 flex items-center">
              <div className="flex flex-column gap-2">
                <label htmlFor="nop">Number of Paragraphs Per Story</label>
                <InputText
                  id="nop"
                  aria-describedby="nop-help"
                  value={settings.number_paragraphs_per_story}
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      number_paragraphs_per_story: parseInt(e.target.value),
                    });
                  }}
                  className="border rounded-lg p-2"
                  keyfilter="int"
                />
              </div>
            </div>
            <div className="w-1/2 flex items-center">
              <div className="flex flex-column gap-2">
                <label htmlFor="noi">Number of Images Per Paragraph</label>
                <InputText
                  id="noi"
                  aria-describedby="noi-help"
                  value={settings.number_of_images_per_paragraph}
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      number_of_images_per_paragraph: parseInt(e.target.value),
                    });
                  }}
                  className="border rounded-lg p-2"
                  keyfilter="int"
                />
              </div>
            </div>
            <div className="w-1/2 flex items-center mt-2">
              <Button
                color={"teal"}
                disabled={submitLoading}
                onClick={updateSettings}
              >
                {submitLoading ? "Submitting..." : "Submit"}
              </Button>
            </div>
          </div>
        )}
      </div>
      <Toast ref={toastRef} />
    </div>
  );
}

export default UserSettings;
