import React, { useEffect, useRef, useState } from "react";
import { Stepper } from "primereact/stepper";
import { StepperPanel } from "primereact/stepperpanel";
import Button from "components/Elements/Button";
import BubblesSelect from "screens/components/BubblesSelect";
import BubbleSelect from "screens/components/BubbleSelect";
import { useDispatch, useSelector } from "react-redux";
import { Mention } from "primereact/mention";
import { ageOptions } from "screens/constants/OptionConstants";
import { categoryOptionsProtagonistCharacteristics } from "screens/constants/OptionConstants";
import { categoryOptionsProtagonistCharacteristicsModeMit } from "screens/constants/OptionConstants";
import { categoryOptionsStoryline } from "screens/constants/OptionConstants";
import { storedIds } from "screens/constants/OptionConstants";
import { fetchHelper } from "screens/helpers/fetchHelpers";
import { setStory_id } from "store/storySlice";
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";
import { useNavigate } from "react-router";
import { setUserObject } from "store/userSlice";
import { setWholeStory } from "store/storySlice";
import { setStoryMeta } from "store/storySlice";
import { storyInitialState } from "store/storySlice";
import { AutoComplete } from "primereact/autocomplete";
import CharacterComponent from "./CharacterComponent";

function SimpleStory({ setTabIndex, setLoading }) {
  const stepperRef = useRef();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const mentionRef = useRef();
  const navigate = useNavigate();
  const story = useSelector((state) => state.story);

  const [form, setForm] = useState({
    age: "",
    otherAge: "",
    lesson: "",
    topic: {
      Genre: "",
      Setting: "",
    },
    hero: "",
    characteristics: {
      Name: "",
      Age: "",
      Gender: "",
      Hair_Color: "",
      Eye_Color: "",
      Skin_Tone: "",
      Loves_To: "",
    },
    style_id: "2",
    isOtherVisible: {
      age: "",
    },
    otherCharacteristics: "",
  });
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const [isCustomSelected, setIsCustomSelected] = useState(false);
  const [stylePresetMain, setStylePresetMain] = useState("watercolor");
  const [imageSourceMode, setImageSourceMode] = useState("Landscape");
  const [characters, setCharacters] = useState([])
  const toastRef = useRef();

  const styleMapping = {
    none: "none",
    "fantasy-art": "fantasy-art",
    cinematic: "cinematic",
    isometric: "isometric",
    "neon-punk": "neon-punk",
    "comic-book": "comic-book",
    watercolor: "2",
    impressionism: "3",
    whimsical: "4",
  };

  const formatSelectionsAsString = (selections) => {
    const entries = Object.entries(selections);
    const nonEmptyEntries = entries.filter(([_, value]) => value);
    const formattedString = nonEmptyEntries
      .map(([key, value]) => `${key} = ${value}`)
      .join(", ");
    return formattedString;
  };

  const [currentSubmitLoading, setCurrentSubmitLoading] = useState(false);

  useEffect(() => {
    if (story.meta !== undefined && story.meta.age !== undefined) {
      let charas = story.meta.hero_characteristics.split(", ");
      let ristics = {};
      let topics = story.meta.topic
        .replace("The story elements are: ", "")
        .split(", ");
      let topic = {};
      topics.forEach((item) => {
        var keys = item.split(" = ");
        topic[keys[0]] = keys[1];
      });
      charas.forEach((item) => {
        var keysValue = item.split(" = ");
        ristics[keysValue[0]] = keysValue[1];
      });
      setStylePresetMain(story.meta.style_id);
      setForm({
        ...form,
        age: story.meta.age,
        otherAge: story.meta.age,
        lesson: story.meta.lesson,
        style_id: story.meta.style_id,
        hero: story.meta.hero_name,
        isOtherVisible: {
          age: !story.meta.age.includes("to"),
        },
        characteristics: ristics,
        topic,
      });
    } else {
      setForm({
        age: "",
        otherAge: "",
        lesson: "",
        topic: {
          Genre: "",
          Setting: "",
        },
        hero: "",
        characteristics: {
          Name: "",
          Age: "",
          Gender: "",
          Hair_Color: "",
          Eye_Color: "",
          Skin_Tone: "",
          Loves_To: "",
        },
        style_id: "2",
        isOtherVisible: {
          age: "",
        },
        otherCharacteristics: "",
      });
    }
  }, [story.meta]);

  const handleSubmit = async () => {
    if (form.lesson.trim() === "") {
      toastRef.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please write a prompt for the story",
      });
      return;
    }

    if (form.age === "" && form.otherAge === "") {
      toastRef.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please Select Age range or write custom age",
      });
      return;
    }

    const topicDescription = formatSelectionsAsString(form.topic);
    let characteristicsDescription = ""

    characters.map(item => {
      characteristicsDescription += formatSelectionsAsString(item.characteristics)
      characteristicsDescription += `, Other Details: ${item.otherCharacteristics}`

    })    

    // Append otherCharacteristics and otherTopic if they have values
    const fullTopicDescription =
      form.otherTopic && form.otherTopic.trim()
        ? `${topicDescription}, Other Topic = ${form.otherTopic}`
        : topicDescription;

    const submissionData = {
      ...form,
      topic: `The story elements are: ${fullTopicDescription}`,
      characteristicsDescription,
    };

    setLoading(true);
    setCurrentSubmitLoading(true);

    var finalData = {
      age: `${
        submissionData.age.toString() | submissionData.otherAge.toString()
      }`,
      lesson: submissionData.lesson,
      topic: submissionData.topic,
      hero_name: form.characteristics.Name || "",
      hero_characteristics:
        characteristicsDescription + ", " + submissionData.otherCharacteristics,
      style_id: styleMapping[stylePresetMain] || form.style_id,
      version: "1",
      added_description: "",
      is_sd3: imageSourceMode === "sd3",
      parent_id: story.id !== null && story.id !== undefined ? story.id : "",
    };

    var { error, message, status } = await fetchHelper(
      `${process.env.REACT_APP_API_BASE_URL}/story/create-story`,
      {
        method: "POST",
        body: JSON.stringify(finalData),
        headers: {
          Authorization: user.token,
          "Content-type": "application/json",
          Accept: "application/json",
        },
      }
    );

    if (status == 401) {
      handleUnauthorized();
    }

    if (error) {
      console.log(error);
      toastRef.current.show({
        severity: "error",
        summary: "Error",
        detail: message,
      });
      setCurrentSubmitLoading(false);
      return;
    }

    dispatch(setWholeStory(storyInitialState));

    dispatch(setStoryMeta(finalData));

    dispatch(setStory_id(message["id"]));

    setLoading(true);
    setTabIndex(2);

    setCurrentSubmitLoading(false);
  };

  const handleUnauthorized = async () => {
    dispatch(
      setUserObject({
        role: "",
        isLoggedIn: false,
        fullName: "",
        token: "",
        session_id: "",
        email: "",
      })
    );
    dispatch(setWholeStory(storyInitialState));
    navigate("/");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleSelectCategory = (category) => {
    setSelectedCategory(category);
    setSelectedSubcategory(""); // Reset subcategory when a new category is selected
    setIsCustomSelected(false);
  };

  const handleSelectSubcategory = (formField, category, subcategory) => {
    setForm((prevForm) => ({
      ...prevForm,
      [formField]: {
        ...prevForm[formField],
        [category]: subcategory,
      },
    }));
  };

  const handleSelectOption = (category, value, isOther = false) => {
    setForm((prevForm) => ({
      ...prevForm,
      [category]: value,
      [`other${category.charAt(0).toUpperCase() + category.slice(1)}`]: isOther
        ? ""
        : prevForm[
            `other${category.charAt(0).toUpperCase() + category.slice(1)}`
          ],
      isOtherVisible: {
        ...prevForm.isOtherVisible,
        [category]: isOther,
      },
    }));
  };

  const handleOtherSelected = (formField) => {
    setIsCustomSelected(true); // Indicate custom selection
    setSelectedCategory("");
    setSelectedSubcategory("");

    setForm((prevForm) => ({
      ...prevForm,
      isOtherVisible: {
        ...prevForm.isOtherVisible,
        [formField]: true,
      },
    }));
  };

  const [suggestions, setSuggestions] = useState(storedIds);

  const onSearch = (event) => {
    //in a real application, make a request to a remote url with the query and return suggestions, for demo we filter at client side
    setTimeout(() => {
      const query = event.query

      let suggestions;

      if (query === "" || query.length === 0) {
        suggestions = [...storedIds];
      } else {
        suggestions = storedIds.filter((customer) => {
          return customer.name.toLowerCase().startsWith(query.toLowerCase());
        });
      }

      setSuggestions(suggestions);
    }, 250);
  };

  // useEffect(() => {
  //   console.log(form);

  //   return () => {};
  // }, [form]);

  return (
    <div className="w-full flex items-center flex-col">
      {user.role !== "sfb" && (
        <div className="w-full flex items-center flex-col">
          <div className="flex items-center w-full dropdown-row justify-center">
            <b>KEY MESSAGE OR LESSON</b>
            <span style={{ color: "red" }}>*</span>
          </div>
          <div className="w-1/2 flex items-center justify-center">            
            <AutoComplete
              value={form.lesson}
              onChange={handleChange}
              id="lesson"
              name="lesson"
              suggestions={suggestions}
              field="name"
              completeMethod={onSearch}
              autoFocus={true}
              placeholder={"Select or type free text"}
              
              size={50}
              showEmptyMessage={""}              
            />
          </div>
        </div>
      )}
      <Stepper
        ref={stepperRef}
        className={`w-1/2 h-full flex flex-col ${
          window.screen.width <= 768 ? "items-center" : ""
        }`}
        orientation={window.screen.width <= 768 ? "vertical" : "horizontal"}
      >
        {user.role !== "sfb" && (
          <StepperPanel
            header="Reader"
            className="w-full flex flex-col items-center"
          >
            <div className="w-full flex flex-col items-center">
              <label htmlFor="age">
                <b>AGE</b>
                {!form.age && <span style={{ color: "red" }}>*</span>}
              </label>
              <div className="dropdown-row justify-center">
                <BubbleSelect
                  options={ageOptions}
                  enableOther={true}
                  selectedValue={form.age}
                  onSelect={(value) => handleSelectOption("age", value)}
                  onOtherSelected={() => handleSelectOption("age", "", true)}
                  isOtherSelected={form.isOtherVisible.age}
                />
              </div>
              <div className="dropdown-row">
                {form.isOtherVisible.age && (
                  <input
                    type="text"
                    value={form.otherAge}
                    onChange={(e) =>
                      setForm({ ...form, otherAge: e.target.value })
                    }
                    placeholder="Add specific age or range"
                    className="mt-2 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  />
                )}
              </div>
              <div className="flex py-4">
                <Button
                  color={"teal"}
                  style={{ marginTop: "27px" }}
                  size={"lg"}
                  onClick={() => {
                    stepperRef.current.nextCallback();
                  }}
                >
                  Next
                </Button>
              </div>
            </div>
          </StepperPanel>
        )}
        <StepperPanel header="Character">
          <div className="w-full flex flex-col items-center">            
            <CharacterComponent characters={characters} setCharacters={setCharacters} />
            <div className="py-4 gap-2">
              <Button
                type="button"
                color={"white"}
                size={"lg"}
                onClick={() => stepperRef.current.prevCallback()}
              >
                Prev
              </Button>
              <Button
                type="button"
                color={"teal"}
                size={"lg"}
                onClick={() => stepperRef.current.nextCallback()}
              >
                Next
              </Button>
            </div>
          </div>
        </StepperPanel>
        <StepperPanel header="Story">
          <div className="w-full flex flex-col items-center">
            <div className="w-full items-center flex flex-col justify-center">
              <label htmlFor="topic">
                <b>ELEMENTS</b>
              </label>
              <BubblesSelect
                categories={categoryOptionsStoryline}
                onSelectCategory={handleSelectCategory}
                onSelectSubcategory={handleSelectSubcategory}
                selectedCategory={selectedCategory}
                selectedSubcategory={form.topic[selectedCategory]}
                enableOther={true}
                onOtherSelected={() => handleOtherSelected("topic")}
                formField="topic"
                form={form}
                setForm={setForm}
              />
            </div>
            <div className="dropdown-row">
              {isCustomSelected && (
                <textarea
                  value={form.otherTopic}
                  onChange={(e) =>
                    setForm({ ...form, otherTopic: e.target.value })
                  }
                  placeholder="Describe custom storyline elements"
                  className="mt-2 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                />
              )}
            </div>
            <div className="style-preset-dropdown ">
              <label htmlFor="stylePreset">Additional style:</label>
              <select
                value={stylePresetMain || "None"}
                name="stylePreset"
                id="stylePreset"
                className="px-4 py-2 ml-2 rounded-full border text-sm font-medium bg-light-teal rounded-input-long"
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  setStylePresetMain(selectedValue);

                  // Update imageSourceMode based on the selected style
                  if (
                    ["watercolor", "impressionism", "whimsical"].includes(
                      selectedValue
                    )
                  ) {
                    setImageSourceMode("Landscape");
                  } else {
                    setImageSourceMode("sd3");
                  }

                  // Set the corresponding style_id
                  const styleId = styleMapping[selectedValue] || "0"; // Default to "0" if not found
                  setForm((prevForm) => ({
                    ...prevForm,
                    selectedStyleId: styleId,
                  }));
                }}
              >
                <option value="" disabled>
                  Select default style
                </option>
                <option value="watercolor">Watercolor</option>
                <option value="impressionism">Impressionism</option>
                <option value="whimsical">Whimsical</option>
                {user.role === "admin" && (
                  <>
                    <option value="fantasy-art">
                      Fantasy Art (Experimental)
                    </option>
                    <option value="cinematic">Cinematic (Experimental)</option>
                    <option value="isometric">Isometric (Experimental)</option>
                    <option value="neon-punk">Neon Punk (Experimental)</option>
                    <option value="comic-book">
                      Comic Book (Experimental)
                    </option>
                  </>
                )}
                <option value="none">None</option>
              </select>
            </div>

            <div className="py-4 gap-2">
              <Button
                type="button"
                color={"white"}
                size={"lg"}
                onClick={() => stepperRef.current.prevCallback()}
              >
                Prev
              </Button>
            </div>
          </div>
        </StepperPanel>
      </Stepper>
      <div className="w-96">
        {currentSubmitLoading ? (
          <ProgressSpinner />
        ) : (
          <div className="w-full">
            <div className="w-full flex flex-grow flex-row space-x-4 gap-2">
              <Button
                color={"teal"}
                size={"lg"}
                fullWidth={true}
                onClick={handleSubmit}
              >
                Submit
              </Button>
              {story.meta !== undefined && story.meta.age !== undefined && (
                <Button
                  color={"teal"}
                  size={"lg"}
                  fullWidth={true}
                  onClick={() => {
                    toastRef.current.show({
                      severity: "info",
                      summary: "Info",
                      detail: "Coming Soon",
                    });
                  }}
                >
                  Submit New Version
                </Button>
              )}
            </div>
            <div className="w-full mt-4">
              {story.meta !== undefined && story.meta.age !== undefined && (
                <Button
                  color={""}
                  size={"lg"}
                  fullWidth={true}
                  onClick={() => {
                    dispatch(setWholeStory(storyInitialState));
                    dispatch(setStoryMeta({}));
                  }}
                >
                  Reset
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
      <Toast ref={toastRef} />
    </div>
  );
}

export default SimpleStory;
