import { TabPanel, TabView } from "primereact/tabview";
import React from "react";
import Story from "./EditStory/Story";
import EditParagraphs from "./EditStory/EditParagraphs";
import EditImages from "./EditStory/EditImages";
import { useSelector } from "react-redux";

function EditStory({
  loadingStory,
  setLoadingStory,
  setTabIndex
}) {
  const { role } = useSelector((state) => state.user);
  return (
    <div className="w-full h-full flex flex-col">
      {/* {role === "admin" && <DirectoryMode />} */}
      <TabView className="w-full h-full">
        <TabPanel header="Story">
          <Story
            loadingStory={loadingStory}
            setLoadingStory={setLoadingStory}
            setTabIndex={setTabIndex}
          />
        </TabPanel>
        <TabPanel header="Paragraphs">
          <EditParagraphs
            loadingStory={loadingStory}
            setLoadingStory={setLoadingStory}
          />
        </TabPanel>
        <TabPanel header="Images">
          <EditImages
            loadingStory={loadingStory}
            setLoadingStory={setLoadingStory}
          />
        </TabPanel>
      </TabView>
      {/* <TimelineComponent /> */}
    </div>
  );
}

export default EditStory;
