const customResponseParser = (response) => {
  return JSON.parse(response.replaceAll("'", '"'));
};

export const fetchHelper = async (url, options) => {
  let data = {
    error: false,
    message: "",
    status: 200,
  };

  try {
    let resp = await fetch(url, options);

    if (resp.status === 200) {
      let response = "";
      try {
        response = await resp.json();
      } catch (e) {
        try {
          response = customResponseParser(await resp.text());
        } catch (e) {
          data.error = true;
          data.message = e.toString();
          data.status = 200;
        }
      }
      data.message = response;
      data.error = false;
      data.status = 200;
    } else if (resp.status === 201) {
      data.error = false;
      data.message = await resp.json();
      data.status = 201;
    } else {
      data.message = await resp.text();
      data.error = true;
      data.status = resp.status;
    }
  } catch (e) {
    data.error = true;
    data.message = e.toString();
    data.status = 500;
  }

  return data;
};
