import React from "react";
import NavbarComponents from "./components/NavbarComponent";
import { useSelector } from "react-redux";
import Button from "components/Elements/Button";
import Input from "components/Elements/Input";
import { ProgressSpinner } from "primereact/progressspinner";
import { useRef } from "react";
import { Toast } from "primereact/toast";
import { useState } from "react";
import { fetchHelper } from "./helpers/fetchHelpers";
import { useEffect } from "react";

function Profile() {
  const user = useSelector((state) => state.user);

  const [specialToken, setSpecialToken] = useState("");
  const [tokenAmount, setTokenAmount] = useState("");
  const [rechargeLoading, setRechargeLoading] = useState(false);

  const [currentTokens, setCurrentTokens] = useState(0);
  const [currentTokenLoading, setCurrentTokenLoading] = useState(true);

  const toastRef = useRef();

  const getCurrentToken = async () => {
    setCurrentTokenLoading(true);
    const { error, message } = await fetchHelper(
      `${process.env.REACT_APP_API_BASE_URL}/user/tokens`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: user.token,
        },
      }
    );

    if (!error) {
      try {
        setCurrentTokens(message["message"]);
      } catch (e) {
        console.log(e);
      }
    } else {
      toastRef.current.show({
        severity: "error",
        summary: "Error",
        detail: message,
      });
    }
    setCurrentTokenLoading(false);
  };

  const handleRecharge = async () => {
    setRechargeLoading(true);

    try {
      const { error, message } = await fetchHelper(
        `${process.env.REACT_APP_API_BASE_URL}/user/tokens`,
        {
          method: "POST",
          body: JSON.stringify({ specialToken, tokenAmount }),
          headers: {
            "Content-Type": "application/json",
            Authorization: user.token,
          },
        }
      );

      if (!error) {
        toastRef.current.show({
          severity: "success",
          summary: "Success",
          detail: "Token added successfully",
        });
        setSpecialToken("");
        setTokenAmount("");
        getCurrentToken();
      } else {
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail: message,
        });
      }
    } catch (e) {
      toastRef.current.show({
        severity: "success",
        summary: "Success",
        detail: "User registered successfully! You can login now.",
      });
      console.log(e);
    }
    setRechargeLoading(false);
  };

  useEffect(() => {
    getCurrentToken();

    return () => {};
  }, []);

  return (
    <div className="bg-white docs-components-wrapper">
      <NavbarComponents whiteBackground={true} showAvatar={true} />
      <div className="w-full p-6 flex flex-col">
        <h2 className="text-lg">
          Welcome, <span className="text-xl font-bold">{user.fullName}</span>
        </h2>
        <div className="w-full flex flex-row mt-4 justify-between">
          <div className="w-1/2 p-2 flex flex-col">
            <h2 className="text-lg">Update Your Profile</h2>
            <div className="relative w-1/2">
              <label className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1">
                Email (disabled)
              </label>
              <Input value={user.email} disabled={true} />
            </div>

            <div className="relative w-1/2">
              <label className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1">
                Address
              </label>
              <Input value={user.address} placeholder="Address" />
            </div>

            <div className="relative w-1/2">
              <label className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1">
                Mobile
              </label>
              <Input value={user.mobile} placeholder="Mobile" />
            </div>

            <div className="relative w-1/2">
              <Button color={"teal"}>Submit</Button>
            </div>
          </div>
          <div className="w-1/2 p-2 flex flex-col">
            <h2 className="text-lg">Recharge Token</h2>
            <div className="relative w-1/2 mt-2 flex flex-row items-center justify-between">
              {currentTokenLoading ? (
                <p>Getting Token Amount ...</p>
              ) : (
                <>
                  <p>Current Tokens: <span className={`font-bold  ${parseInt(currentTokens) >= 50 ? 'text-green-400' : 'text-red-400'}`}>{currentTokens}</span></p>
                  <Button color={"teal"} onClick={getCurrentToken}>
                    Refresh
                  </Button>
                </>
              )}
            </div>
            <div className="relative w-1/2 mt-2">
              <label className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1">
                Special Token
              </label>
              <Input
                value={specialToken}
                type="password"
                onChange={(e) => setSpecialToken(e.currentTarget.value)}
                placeholder="Special Token"
              />
            </div>
            <div className="relative w-1/2">
              <label className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1">
                Token Amount
              </label>
              <Input
                value={tokenAmount}
                onChange={(e) => setTokenAmount(e.currentTarget.value)}
                placeholder="Token Amount"
              />
            </div>
            <div className="relative w-1/2">
              {rechargeLoading ? (
                <ProgressSpinner />
              ) : (
                <Button color={"teal"} onClick={handleRecharge}>
                  Recharge
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <Toast ref={toastRef} />
    </div>
  );
}

export default Profile;
