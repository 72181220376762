import { createSlice } from "@reduxjs/toolkit";

export const storyInitialState = {
  id: null,
  title: "",
  content: [],
  commands: [],
  meta: {},
  elements: []
};

const initialState = storyInitialState

export const storySlice = createSlice({
  name: "story",
  initialState,
  reducers: {
    setStory_id: (state, action) => {
      state.id = action.payload;
    },
    setStoryTitle: (state, action) => {
      state.title = action.payload;
    },
    setImage: (state, action) => {
      state.content[action.payload['paragraph']]['images'].push(action.payload)
    },
    updateParaText: (state, action) => {
      let para_index = parseInt(action.payload["index"]);

      state.content[para_index]["text"] = action.payload["text"];
    },
    updateCommands: (state, action) => {
      if (action.payload === null) {
        state.commands = [];
      } else {
        state.commands.push(action.payload);
      }
    },
    updateSelectedImage: (state, action) => {
      action = action.payload;
      let para = state.content[action["para"]];
      para.images.forEach((image, index) => {
        if (index === action["image"]) {
          image["selected"] = true;
        } else {
          image["selected"] = false;
        }
      });
      state.content[action["para"]] = para;
    },
    setWholeStory: (state, action) => {
      state = action.payload;
      return state;
    },
    updateImageUrl: (state, action) => {
      let para = state.content[action["payload"]["para"]];
      para.image.images.forEach((image, index) => {
        if (image["url"] === action["payload"]["image_url"]) {
          image["url"] = action["payload"]["image"];
        }
      });
      state.content[action["payload"]["para"]] = para;
    },
    addNewParatoContent: (state, action) => {
      var tempArr = state.content
      var para_index = action.payload.para_index
      tempArr[para_index] = action.payload
      state.content = tempArr
    },
    setCoverImageURL: (state, action) => {
      state.cover_url = action.payload;
    },
    setStoryMeta: (state, action) => {
      state.meta = {...action.payload}      
    },
    addNewElements: (state, action) => {
      state.elements.push(action.payload)
    },
    updateAllContent: (state, action) => {
      state.content = action.payload
    }
  },
});

export const {
  setStory_id,
  setImage,
  updateParaText,
  updateCommands,
  updateSelectedImage,
  setWholeStory,
  updateImageUrl,
  setStoryTitle,
  addNewParatoContent,
  setCoverImageURL,
  setStoryMeta,
  addNewElements,
  updateAllContent
} = storySlice.actions;
export default storySlice.reducer;
