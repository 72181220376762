import React from "react";
import NavbarComponents from "./components/NavbarComponent";
import { useSelector } from "react-redux";

import { TabView, TabPanel } from "primereact/tabview";
import UserSettings from "./UserAdmin/UserSettings";
import AdminComponent from "./UserAdmin/AdminComponent";

function UserAdmin() {
  const user = useSelector((state) => state.user);

  return (
    <div className="bg-white docs-components-wrapper">
      <NavbarComponents whiteBackground={true} showAvatar={true} />
      <div className="w-full p-6 flex flex-col">
        <h2 className="text-lg">
          Welcome, <span className="text-xl font-bold">{user.fullName}</span>
        </h2>

        <TabView>
          <TabPanel header="Story Settings">
            <UserSettings />
          </TabPanel>
          {user.role === "admin" && (
            <TabPanel header="User Settings">
              <AdminComponent />
            </TabPanel>
          )}
        </TabView>
      </div>
    </div>
  );
}

export default UserAdmin;
