import React, { useState, useRef, useEffect } from "react";
import { Toast } from "primereact/toast";
import { useSelector } from "react-redux";
import { ProgressSpinner } from "primereact/progressspinner";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { fetchHelper } from "screens/helpers/fetchHelpers";

function AdminComponent() {
  const user = useSelector((state) => state.user);

  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(true);

  const toastRef = useRef();

  const getUsers = async () => {
    setUsersLoading(true);
    const { error, message } = await fetchHelper(
      `${process.env.REACT_APP_API_BASE_URL}/user/update-users`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: user.token,
        },
      }
    );

    if (error) {
      toastRef.current.show({
        severity: "error",
        summary: "Error",
        detail: message,
      });
    } else {
      setUsers(message);
    }
    setUsersLoading(false);
  };

  const updateUserRole = async (id, role) => {
    const { error, message } = await fetchHelper(
      `${process.env.REACT_APP_API_BASE_URL}/user/update-users`,
      {
        method: "POST",
        body: JSON.stringify({ id, role }),
        headers: {
          "Content-Type": "application/json",
          Authorization: user.token,
        },
      }
    );

    if (error) {
      toastRef.current.show({
        severity: "error",
        summary: "Error",
        detail: message,
      });
    } else {
      getUsers();
    }
  };

  useEffect(() => {
    getUsers();

    return () => {};
  }, []);

  const roles = [
    { name: "Admin", value: "admin" },
    { name: "Customer", value: "Customer" },
  ];

  const roleBodyTemplate = (user_instance) => {
    return (
      <Dropdown
        value={user_instance.role}
        onChange={(e) => updateUserRole(user_instance.id, e.value)}
        options={roles}
        optionLabel="name"
        placeholder="Select a role"
        className="w-full md:w-14rem"
        filter
      />
    );
  };

  return (
    <div className="w-full flex flex-row mt-4 justify-between">
      <div className="w-full p-2 flex flex-col">
        <h2 className="text-lg">Update User Roles</h2>
        {usersLoading ? (
          <ProgressSpinner />
        ) : (
          <DataTable value={users}>
            <Column field="id" header="ID"></Column>
            <Column field="name" header="Name"></Column>
            <Column field="email" header="Email"></Column>
            <Column field="coins" header="Coins"></Column>
            <Column field="role" header="Role" body={roleBodyTemplate}></Column>
          </DataTable>
        )}
      </div>
      <Toast ref={toastRef} />
    </div>
  );
}

export default AdminComponent;
