import Button from "components/Elements/Button";
import { Avatar } from "primereact/avatar";
import { OverlayPanel } from "primereact/overlaypanel";
import React from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { storyInitialState } from "store/storySlice";
import { setWholeStory } from "store/storySlice";
import { setUserObject } from "store/userSlice";

export default function NavbarComponents({
  whiteBackground = false,
  showAvatar = false,  
  showSidebar = undefined,
  setShowSidebar,
}) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const user = useSelector((state) => state.user);
  const ovRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = async () => {
    dispatch(
      setUserObject({
        role: "",
        isLoggedIn: false,
        fullName: "",
        token: "",
        session_id: "",
        email: "",
      })
    );
    dispatch(
      setWholeStory(storyInitialState)
    );
    navigate("/");
  };

  return (
    <>
      <nav
        className={`${
          whiteBackground ? "" : "fixed"
        } w-full bg-teall-900 flex flex-wrap items-center justify-between px-2 py-3 z-9999`}
      >
        <div className="w-full px-4 mx-auto flex flex-wrap items-center justify-between max-h-screen-70 overflow-y-auto">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <div className="w-full flex flex-row">
              {showSidebar != undefined && (
                <button
                  className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block outline-none focus:outline-none"
                  type="button"
                  onClick={() => {console.log("Button clicked"); setShowSidebar(!showSidebar)}}
                >
                  <i
                    className={`${
                      whiteBackground ? "text-black" : "text-white"
                    } fas fa-bars`}
                  ></i>
                </button>
              )}
              <Link
                to="/#"
                className={`${
                  whiteBackground ? "text-black" : "text-white"
                } text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase`}
              >
                storyfave
              </Link>
            </div>
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i
                className={`${
                  whiteBackground ? "text-black" : "text-white"
                } fas fa-bars`}
              ></i>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none" +
              (navbarOpen ? " block rounded lg:shadow-lg" : " hidden")
            }
          >
            <ul className="flex flex-col lg:flex-row list-none mr-auto lg:items-center">
              <li>
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSfHD-Wzm0SS8EFLdOR5MMxBoGsCAS9PPNo17JOvhjOV9tWYfA/viewform"
                  className={`hover:opacity-75 lg:${
                    whiteBackground ? "text-black" : "text-white"
                  } px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold transition-all duration-150 ease-in-out text-blueGray-800`}
                >
                  Feedback
                </a>
              </li>
            </ul>
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto lg:items-center space-x-1">
              <li>
                <a
                  className="text-blueGray-800 hover:text-blueGray-600 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  href="#pablo"
                >
                  <i
                    className={`text-blueGray-500  lg:${
                      whiteBackground ? "text-black" : "text-white"
                    } fab fa-instagram text-lg leading-lg`}
                  />
                  <span className="lg:hidden inline-block ml-2">Instagram</span>
                </a>
              </li>
              <li>
                <a
                  className="text-blueGray-800 hover:text-blueGray-600 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  href="#pablo"
                >
                  <i
                    className={`text-blueGray-500 lg:${
                      whiteBackground ? "text-black" : "text-white"
                    } fab fa-facebook text-lg leading-lg `}
                  />
                  <span className="lg:hidden inline-block ml-2">Facebook</span>
                </a>
              </li>

              <li>
                <a
                  className="text-blueGray-800 hover:text-blueGray-600 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  href="#pablo"
                >
                  <i
                    className={`text-blueGray-500 lg:${
                      whiteBackground ? "text-black" : "text-white"
                    } fab fa-twitter text-lg leading-lg `}
                  />
                  <span className="lg:hidden inline-block ml-2">Tweet</span>
                </a>
              </li>
              <li>
                <a
                  href="mailto:admin@storyfave.com?subject=Careers"
                  className="text-xs px-4 py-2 shadow hover:shadow-md text-white bg-blueGray-800 border-blueGray-800 active:bg-blueGray-900 active:border-blueGray-900 rounded outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out uppercase border border-solid font-bold mr-2"
                >
                  Careers
                </a>
              </li>
              <li>
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSfHD-Wzm0SS8EFLdOR5MMxBoGsCAS9PPNo17JOvhjOV9tWYfA/viewform"
                  className="text-xs px-4 py-2 shadow hover:shadow-md text-blueGray-800 bg-blueGray-100 border-blueGray-100 active:bg-blueGray-300 active:border-blueGray-300 rounded outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out uppercase border border-solid font-bold"
                >
                  Contact Us
                </a>
              </li>
              {user.isLoggedIn ? (
                <li className="cursor-pointer z-10">
                  <Avatar
                    label={user.fullName[0].toUpperCase()}
                    className="bg-purple-400 text-white cursor-pointer"
                    shape="circle"
                    onClick={(e) => {
                      ovRef.current.toggle(e);
                    }}
                  />
                  <OverlayPanel ref={ovRef}>
                    <div className="flex flex-col">
                      <p className="font-bold mb-2 mt-2">
                        Hello, {user.fullName}
                      </p>
                      <div
                        className="w-full bg-gray-500"
                        style={{ height: "1px" }}
                      />
                      <a className="p-2" href="/#/dashboard/workspace">
                        Workspace
                      </a>
                      <a className="p-2" href="/#/dashboard/profile">
                        Profile
                      </a>
                      <a className="p-2" href="/#/dashboard/characters">
                        Characters
                      </a>                      
                       <a className="p-2" href="/#/dashboard/users">
                        User Setting
                      </a>
                      <a onClick={handleLogout} className="p-2 cursor-pointer">
                        Logout
                      </a>
                    </div>
                  </OverlayPanel>
                </li>
              ) : (
                <li>
                  <Button
                    color={"teal"}
                    onClick={() => {
                      navigate("/login");
                    }}
                    size={"sm"}
                  >
                    Login
                  </Button>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
