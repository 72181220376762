const BubbleSelect = ({ options, onSelect, selectedValue, enableOther, onOtherSelected, isOtherSelected }) => {
    const handleSelection = (value) => {
        onSelect(value); // Call the onSelect handler with the selected value
        if (value === 'Other') {
            onOtherSelected(); // If "Other" is selected, call the onOtherSelected handler
        }
        console.log(options);
    };

    return (
        <div className="flex flex-wrap gap-2 justify-center">
            {options.map((option) => (

                <button
                    key={option.value}
                    onClick={() => handleSelection(option.value)}
                    className={`px-4 py-2 rounded-full border text-sm font-medium ${selectedValue === option.value ? 'bg-teal-500 text-white' : 'bg-white text-gray-800'
                        } hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500`}
                >
                    {option.label}
                </button>
            ))}
            {enableOther && (
                <button
                    onClick={() => handleSelection('Other')}
                    className={`px-4 py-2 rounded-full border text-sm font-medium ${isOtherSelected ? 'bg-teal-500 text-white' : 'bg-white text-gray-800'
                        } hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500`}
                >
                    Custom Age 💫
                </button>
            )}
        </div>
    );
};

export default BubbleSelect