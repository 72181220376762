import React from 'react'

function Footer() {
    return (
        <footer className="relative pt-8 pb-6 mt-8 bg-white">
            <div className="container mx-auto px-4">
                <div className="flex flex-wrap">
                    <div className="w-full md:w-6/12 px-4">
                        <h4 className="text-xl leading-normal font-bold">
                            Let's keep in touch!
                        </h4>
                        <h5 className="leading-normal mt-1 mb-2 text-blueGray-500">
                            Find us on any of these platforms.
                        </h5>
                        <div className="mt-6">
                            <a href="https://www.instagram.com/storyfave" target="_blank">
                                <i className="fab fa-instagram  bg-white text-instagram-regular shadow-lg font-lg p-3 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2 inline-block text-center"></i>
                            </a>
                            <a href="https://www.storyfave.com" target="_blank">
                                <i className="fab fa-facebook-square bg-white text-facebook-regular shadow-lg font-lg p-3 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2 inline-block text-center"></i>
                            </a>
                            <a href="http://x.com/beagoodbot" target="_blank">
                                <i className="fab fa-twitter bg-white text-twitter-regular shadow-lg font-lg p-3 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2 inline-block text-center"></i>
                            </a>
                        </div>
                    </div>
                    <div className="w-full md:w-6/12 px-4">
                        <div className="flex flex-wrap items-top mb-6">
                            <div className="w-full md:w-6/12 xl:w-4/12 pt-6 md:pt-0 md:px-4 ml-auto">
                                <span className="block uppercase text-xs font-bold mb-2">
                                    Useful Links
                                </span>
                                <ul className="list-unstyled">
                                    <li>
                                        <a
                                            className="text-blueGray-500 hover:text-blueGray-700 block pb-2 text-sm"
                                            href="https://www.storyfave.com"
                                            target="_blank"
                                        >
                                            Contact Us
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="w-full md:w-6/12 xl:w-4/12 pt-6 md:pt-0 md:px-4 ml-auto">
                                <span className="block uppercase text-xs font-bold mb-2">
                                    Other Resources
                                </span>
                                <ul className="list-unstyled">
                                    <li>
                                        <a
                                            className="text-blueGray-500 hover:text-blueGray-700 block pb-2 text-sm"
                                            href="mailto:admin@storyfave.com?subject=Careers"
                                            target="_blank"
                                        >
                                            Careers
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="text-blueGray-500 hover:text-blueGray-700 block pb-2 text-sm"
                                            href="https://www.storyfave.com"
                                            target="_blank"
                                        >
                                            Terms of Use
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="text-blueGray-500 hover:text-blueGray-700 block pb-2 text-sm"
                                            href="https://www.storyfave.com"
                                            target="_blank"
                                        >
                                            Privacy Policy
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="my-6 border-blueGray-200" />
                <div className="flex flex-wrap items-center md:justify-between justify-center">
                    <div className="w-full md:w-4/12 px-4 mx-auto text-center">
                        <div className="text-sm text-blueGray-500 py-1">
                            Copyright © {new Date().getFullYear()}{" "}
                            <a
                                href="https://www.storyfave.com"
                                className="text-teal-500 hover:text-blueGray-600 font-semibold"
                                target="_blank"
                            >
                                StoryFave
                            </a>
                            . All rights reserved.
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer