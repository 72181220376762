import React from 'react'
import NavbarComponents from './components/NavbarComponent'
import Header from './components/Header'
import HeadingBadge from './components/HeadingBadge'
import VideoCard from './components/VideoCard'
import Footer from './components/Footer'
import { useState } from 'react'

function Home() {
    const [displayDialog, setDisplayDialog] = useState(false)

    return (
        <div className="bg-white docs-components-wrapper">
            <NavbarComponents />
            <Header video_or_image={"assets/img/illustrations/open_book.png"} title="storyfave" subtitle={"Meaningful stories by you— a platform where everybody's story matters."} description={"We’re not always in the position that we want to be at. We’re constantly growing. We’re constantly making mistakes. We’re constantly trying to express ourselves and actualize our dreams. If you have the opportunity to play this game of life you need to appreciate every moment. A lot of people don’t appreciate the moment until it’s passed."} icon={"fas fa-rocket"} color={"green"} image={require("assets/img/illustrations/bg-presentation.svg").default} skew={{
                color: "white",
            }} buttonColor={"teal"} buttonText={"Start Now"} buttonPress={() => setDisplayDialog(true)} />
            <div className='bg-blueGray-100'>
                <div className='w-full px-16 mx-auto mt-12 mb-6 pt-6'>
                    <HeadingBadge badge={{ color: "lightBlue", children: "Try For Free" }} title={"Customize Your Story"} description={"Choose or Add Details Below"} alignment={"center"} />
                </div>
                <div className='flex flex-wrap mx-4'>
                    <div className='mx-auto px-4 relative w-full md:w-8/12'>
                        <VideoCard displayDialog={displayDialog} setDisplayDialog={setDisplayDialog} />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Home