import { ProgressSpinner } from "primereact/progressspinner";
import React from "react";
import { useSelector } from "react-redux";
import ParagraphComponent from "screens/components/ParagraphComponent";

function EditParagraphs({ loadingStory, setLoadingStory }) {
  const story = useSelector((state) => state.story);

  return (
    <div className="w-full flex justify-center items-center">
      {loadingStory ? (
        <ProgressSpinner />
      ) : (
        <div className="w-full flex flex-col">
          <div className="w-full flex items-center justify-center">
            <h2 className="story-title">{story.title}</h2>
          </div>

          {story.content.map((item, index) => {
            return <ParagraphComponent {...item} key={index} index={index} show={"text"} />;
          })}
        </div>
      )}
    </div>
  );
}

export default EditParagraphs;
